import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NotFound from './Errors/NotFound';

import Signin from './Signin';
import Auth from './Auth/index.js';
import User from './User';
// import TopLoadingBar from '../components/TopLoadingBar';

export default function Routes() {
  return (
    <>
      {/* <TopLoadingBar /> */}
      <Router>
        <Switch>
          <Route exact path="/admin/*" component={User} />
          <Route exact path="/auth/*" component={Auth} />
          <Route exact path="/" component={Signin} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}