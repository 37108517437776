/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';

import styles from "./styles.module.css";

import Header from "../../../../components/Header";
import Modal from "../../../../components/Modal";
import ButtonLoading from "../../../../components/Buttons/ButtonLoading";
import Button from "../../../../components/Buttons/Button"

import { Form, Col, Row } from "react-bootstrap";

import MaskedFormControl from 'react-bootstrap-maskedinput';

import api from "../../../../services/api";
import { API_GUARD } from "../../../../services/env";

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      document: "",
      validate: {
        name: {
          message: "",
          isInvalid: false,
        },
        document: {
          message: "",
          isInvalid: false,
        },
      },
      isLoadingForm: true,
      isLoading: false,
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      },
    };
    this.getEditCompany = this.getEditCompany.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getEditCompany();
  }

  async getEditCompany() {
    this.toggleIsLoadForm(true);
    const { params } = this.props.match;
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/company/${params.id}/edit`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { company } = response.data;
      const { name, document } = company;

      this.setState({ name, document });

    }
    this.toggleIsLoadForm(false);
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleIsLoadForm(boolean) {
    this.setState({ isLoadingForm: boolean });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerUpdate(event) {
    event.preventDefault();
    const { params } = this.props.match;
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        name,
        document,
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.put(
          `/v1/${API_GUARD}/company/${params.id}`,
          {
            name,
            document,
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };

    return (
      <div className={`${styles.container}`}>
        <Header title="Empresas" description="Gerenciamento de Empresas" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Editar Empresa</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerUpdate(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nome:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.name}
                      isInvalid={this.state.validate.name.isInvalid}
                      type="text"
                      name="name"
                      value={this.state.name}
                      placeholder="Nome da empresa"
                      onChange={event =>
                        this.handlerChangeInput({ name: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.name.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="document">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    CNPJ:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <MaskedFormControl
                      mask={'11.111.111/1111-11'}
                      placeholderChar=" "
                      className={this.state.validate.document.isInvalid && "is-invalid"}
                      type="tel"
                      name="document"
                      value={this.state.document}
                      placeholder="CPF ou CNPJ"
                      onChange={event =>
                        this.handlerChangeInput({
                          document: event.target.value.replace(/\D/g, "")
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.document.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/> 
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Salvar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.toggleAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EditCompany;