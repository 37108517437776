import React, { Component } from 'react';

import styles from './styles.module.css';
import Modal from '../../../../../../../components/Modal';
import ModalConfirm from '../../../../../../../components/ModalConfirm';


import api from '../../../../../../../services/api';
import { API_GUARD } from '../../../../../../../services/env';

export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: {
        title: "",
        message: "",
        isAlertOn: false,
      },
      modalConfirm: {
        title: "",
        text: ""
      }
    }
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  toggleTableLine(id) {
    const tr = document.getElementById(id);
    if (tr.classList.contains('d-none')) {
      tr.classList.remove('d-none');
    } else {
      tr.classList.add('d-none');
    }
  }

  toggleAlert(boolean) {
    this.setState({
      isAlertOn: boolean,
    });
  }
  handlerActionSelectChange(value, id) {
    switch (value) {
      // case "edit":
      //   this.handlerHeaderEditTelephone(id);
      //   break;
      case "unlink":
        this.handlerHeaderUnlinkTelephone(id,value);
      break;
      case "main":
        this.handlerHeaderMainTelephone(id,value);
        break;

      default:
    }
  }

  handlerHeaderMainTelephone(id,value) {
    this.toggleConfirm(id,value);
  }


  
  toggleConfirm(branch,value) {
    if (branch) {
      if(value==='main'){
      this.setState({
        id: branch,
        isExclusionConfirmOn: !this.state.isExclusionConfirmOn,
        modalConfirm: {
          title: "Definir como principal",
          message: `Deseja definir esse número de telefone como o principal do PABX?`
        }
      });
    }else{
      this.setState({
        id: branch,
        isExclusionConfirmOn: !this.state.isExclusionConfirmOn,
        modalConfirm: {
          title: "Desvincular telefone",
          message: `Tem certeza que deseja desvincular esse telefone?`
        }
      });
    }
    } else {
      this.setState({
        id: null,
        isExclusionConfirmOn: false,
        modalConfirm: {
          title: "",
          message: ""
        }
      });
    }
  }
  

      async handlerConfirm(value) {
    const id = this.state.id;

    const pabx_cod = this.props.item.pabx_id;
        if(value==='main'){
          try {
            const access_token = await localStorage.getItem("access_token");
            const response = await api.post(`/v1/${API_GUARD}/pabx/${pabx_cod}/telephone/${id}/main`,{}, {
              headers: { Authorization: "Bearer " + access_token }
            });
            if (response.status && response.status === 200) {

              document.location.reload(true);
              const { title, message } = response.data;
              this.activeAlert({ title, message });
            }
            this.getTelephones(pabx_cod);
          } catch (error) {
            if (error.response) {
              const { data } = error.response;
              const { errors } = data;
              switch (error.response.status) {
                case 400:
                  this.activeAlert({ title: data.title, message: data.message });
                  break;
                case 404:
                  this.activeAlert({ title: data.title, message: data.message });
                  break;
                case 422:
                  for (let key in errors) {
                    this.setState({
                      validate: {
                        ...this.state.validate,
                        [key]: {
                          message: errors[key],
                          isInvalid: true
                        }
                      }
                    });
                  }
                  break;
                default:
              }
            }
          }
        }else{
            try {
              const access_token = await localStorage.getItem("access_token");
              const response = await api.delete(`/v1/${API_GUARD}/pabx/${pabx_cod}/telephone/${id}`, {
                headers: { Authorization: "Bearer " + access_token }
              });
              if (response.status && response.status === 200) {

                document.location.reload(true);
                const { title, message } = response.data;
                this.activeAlert({ title, message });
              }
              this.getTelephones(pabx_cod);
            } catch (error) {
              if (error.response) {
                const { data } = error.response;
                const { errors } = data;
                switch (error.response.status) {
                  case 400:
                    this.activeAlert({ title: data.title, message: data.message });
                    break;
                  case 404:
                    this.activeAlert({ title: data.title, message: data.message });
                    break;
                  case 422:
                    for (let key in errors) {
                      this.setState({
                        validate: {
                          ...this.state.validate,
                          [key]: {
                            message: errors[key],
                            isInvalid: true
                          }
                        }
                      });
                    }
                    break;
                  default:
                }
              }
            }
        }
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title: title ? title : "",
        message
      }
    });
  }


  // handlerHeaderEditTelephone(id) {
  //   const { history } = this.props;
  //   history.push(`/admin/PABX/numeros/${id}/editar`);
  // }

  async handlerHeaderUnlinkTelephone(id) {
    this.toggleConfirm(id)
  }

  render() {
    return (
      <>
        <tr>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.id + 1
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.item.ddd
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.item.number
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.city != null ? this.props.item.city.name : "Não atribuída"
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.main ? "Sim"  : "Não"
          }</td>
          <td className={`export ${styles.select}`}>{
            !(this.props.item.pabx) ?
              (
                <select name="action" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
                  <option value="">Nenhuma</option>
                  {/* <option value="edit">Editar</option> */}
                  <option value="unlink">Desvincular</option>
                  {!this.props.item.main?
                    <option value="main">Definir como principal</option>
                  :''}
                </select>
              )
              : (
                <select disabled aria-readonly name="action" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
                  <option value="">Nenhuma</option>
                </select>
              )

          }</td>
        </tr>
        <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
          <ModalConfirm
            isActive={this.state.isExclusionConfirmOn}
            toggleConfirm={this.toggleConfirm}
            handlerConfirm={() => { this.state.modalConfirm.title==='Desvincular telefone' ? this.handlerConfirm('unlink') : this.handlerConfirm('main'); this.toggleConfirm(null) }}
            title={this.state.modalConfirm.title}
            text={this.state.modalConfirm.message}
          />
      </>
    );
  }
}
