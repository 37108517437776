import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

class MultiSelectWithAddNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelected: [],
      options: [],
    }
  }

  onChange(event) {
    const { onChange, multiple } = this.props;
    const values = multiple ? event.map((item) => typeof item === 'object' ? item.label : item) : event;
    onChange(values);
  }

  componentDidMount() {
    const { defaultSelected, options } = this.props;

    this.setState({ defaultSelected, options });
  }

  componentDidUpdate(prevProps) {
    const { cleanMultiSelect } = this.props;
    if (prevProps.cleanMultiSelect !== cleanMultiSelect) {
      this._typeahead.clear();
    }

    const { defaultSelected } = this.props;
    if (prevProps.defaultSelected !== defaultSelected) {
      // console.log(defaultSelected);
      this.setState({ defaultSelected });
    }
  }

  render() {
    const { placeholder, newSelectionPrefix, multiple, isLoading, isInvalid } = this.props;
    const { defaultSelected, options } = this.state;

    return (
      <React.Fragment>
        <Typeahead
          selectHintOnEnter={true}
          className={isInvalid && "is-invalid"}
          isInvalid={isInvalid}
          isLoading={isLoading}
          allowNew
          id=""
          multiple={multiple}
          newSelectionPrefix={newSelectionPrefix}
          options={options}
          selected={defaultSelected}
          placeholder={placeholder}
          onChange={(event) => { this.onChange(event); }}
          ref={(ref) => this._typeahead = ref}
        />
      </React.Fragment>
    );
  }
}

export default MultiSelectWithAddNew;