import { ONLY_NUMBER } from "./regex";

export const mascaraCnpj = (valor) => {
  if(valor.length>13){
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
  }else{
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
}

// export const mascaraCpf = (valor) => {
//   return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
// }

export const intToBRLCurrency = (int) => {
  if (int) {
    const currency = parseInt(int) / 100;
    return currency.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
  }
  return "";
}

export function integerToBRLCurrency(integer) {
  let currency = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 });

  if (Object.is(integer, -0)) {
    return "-" + currency;
  } else {
    currency = (integer / 10000).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 });
  }

  return currency;
}

export const BRLCurrencyToInt = (BRLCurrency) => {
  if (BRLCurrency) {
    const int = BRLCurrency.replace(/[^\-\+0-9]+/g, '').replace(/^(\d*)(\-?)(\+?)$/, "$3$2$1");

    console.log(int);

    if (int.match(/\++/)) {
      return parseInt(int.replace("-",""));
    }

    return parseInt(int);
  }
  return "";
}

function formatCelNumber(number) {
  switch (number.length) {
    case 11:
      const only_numbers_and_put_parentheses = number.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      const numbers_and_parentheses_and_hyphen = only_numbers_and_put_parentheses.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
      return numbers_and_parentheses_and_hyphen;
    case 9:
      const numbers_and_hyphen = number.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
      return numbers_and_hyphen;
    default:
      return number;
  }
}

function formatPhoneNumber(number) {
  switch (number.length) {
    case 10:
      const only_numbers_and_put_parentheses = number.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      const numbers_and_parentheses_and_hyphen = only_numbers_and_put_parentheses.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
      return numbers_and_parentheses_and_hyphen;
    case 8:
      const numbers_and_hyphen = number.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
      return numbers_and_hyphen;
    default:
      return number;
  }
}

export function phoneMask(string) {
  if (string) {
    const only_numbers = stringToNumber(string).toString(); //Remove tudo o que não é dígito e zero a esquerda
    if (only_numbers.substring(0, 4) === "0800") {
      return string;
    }
    switch (checkPhoneType(only_numbers)) {
      case "mobile":
        return formatCelNumber(only_numbers);
      case "phone":
        return formatPhoneNumber(only_numbers);
      default:
        return only_numbers;
    }
  }
}

function checkPhoneType(number) {
  const lastChar = number.slice(-1);
  const checker = number.substring(
    number.lastIndexOf(lastChar) - 8, number.lastIndexOf(lastChar) - 7
  )
  if (parseInt(checker) === 9) {
    return "mobile";
  } else {
    return "phone";
  }
}

function stringToNumber(string) {
  const number = parseInt(string.replace(/\D/g, ""));
  return number;
}