import React, { Component } from 'react';

import styles from './styles.module.css';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

import Header from '../../../../components/Header';

export default class ReportPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
        quantidade:'',
        telephones:''
    };
  }
  
  async getTelephones() {
    try {
      const value = this.state.quantidade  
      const body = {
                        "quantity_phones" : value
                    }
    
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/report/phones_available_by_city`;
      const response = await api.post(url, body, {
        headers: { Authorization: "Bearer " + access_token },
      });
      if (response.status && response.status === 200) {
        
        console.log(response.data)
        this.setState({telephones: response.data.data})
        
      }
    } catch (error) {
        console.log(error)
    }
    
  }

  handleSubmit(event){
    this.getTelephones()
    event.preventDefault();
  }
 

  render() {
    const {quantidade, telephones} = this.state
    return (
      <>
        <div className={styles.container}>
          <Header title="Relátorio" description="Telefones" />
          <div className={styles.body}>
            <div className={styles.headReport}>
                <h2 className={styles.title}>Relatório de telefones</h2>
                <form onSubmit={(e)=>{this.handleSubmit(e)}}>
                <div className={styles.inputContainer}>
                    <strong><label>Quantidade de números de telefone:</label></strong>
                    <div className={styles.search}>
                      <div>
                        <input value={quantidade} type="number" onChange={(e)=>{this.setState({quantidade:e.target.value})}}/>
                      </div>
                      <button className={styles.btn}>Buscar</button>
                    </div>
                </div>
                </form>
            </div>
            <div className={styles.table}>
                <table>
                    <thead>
                        <th>Cidade</th>
                        <th>DDD</th>
                        <th>Quantidade</th>
                    </thead>
                    <tbody>
                        {telephones ? 
                        telephones.map((item, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.city}</td>
                                    <td>{item.ddd}</td>
                                    <td>{item.quantity}</td>
                                </tr>
                            )
                        })
                        : 
                        <tr>
                          <td colSpan='3'>Nenhum registro encontrado</td>
                        </tr>
                        }
                        
                    </tbody>
                </table>
            </div>
           
          </div>  
        </div>
      </>
    );
  }
}