import React, { Component } from 'react';

import styles from './styles.module.css';

import * as moment from 'moment';

export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      alertMessage: {
        title: "",
        message: "",
        isAlertOn: false,
      }
    }
  }

  toggleTableLine(id) {
    const tr = document.getElementById(id);
    if (tr.classList.contains('d-none')) {
      tr.classList.remove('d-none');
    } else {
      tr.classList.add('d-none');
    }
  }

  handlerActionSelectChange(value, id) {
    switch (value) {
      case "edit":
        this.handlerHeaderEdit(id);
        break;
      case "delete":
        this.handlerDelete();
        break;
      default:

    }
  }

  handlerHeaderEdit(id) {
    const { history } = this.props;
    const { params } = this.props.match;
    const { company_id } = params;

    history.push(`/admin/company/${company_id}/assinaturas/${id}/editar`);
  }

  handlerDelete() {
    this.props.toggleConfirm(this.props.item);
  }

  render() {
    return (
      <>
        <tr>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.id + 1
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            `${this.props.item.pabx.name} (${this.props.item.pabx.code})`
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.item.plan.name
          }</td>
          <td className="export d-none d-lg-table-cell">{
            moment(this.props.item.current_period_start).format("D/MM/Y")
          }</td>
          <td className="export d-none d-lg-table-cell">{
            moment(this.props.item.current_period_end).format("D/MM/Y")
          }</td>
          <td className={`export ${styles.select}`}>
            <select name="action" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
              <option value="">Nenhuma</option>
              <option value="edit">Editar</option>
              <option value="delete">Deletar</option>
            </select>
          </td>
        </tr>
        <tr className="d-none"></tr>
        <tr className="d-none" id={`toggle-${this.props.id}`}>
          <td colSpan="6">
            <p><strong>Data início:</strong> {moment(this.props.item.current_period_start).format("D/MM/Y HH:mm:ss")}</p>
            <p><strong>Data Fim:</strong> {moment(this.props.item.current_period_end).format("D/MM/Y HH:mm:ss")}</p>
          </td>
        </tr>
      </>
    );
  }
}
