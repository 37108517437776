import React, { Component } from 'react';

import styles from "./styles.module.css";

import Header from "../../../../../components/Header";
import Modal from "../../../../../components/Modal";
import MultiSelect from "../../../../../components/Inputs/Bootstrap/MultiSelect";
import ButtonLoading from "../../../../../components/Buttons/ButtonLoading";
import Button from '../../../../../components/Buttons/Button';


import { Form, Col, Row } from "react-bootstrap";
import * as moment from 'moment';

import api from "../../../../../services/api";
import { URL, API_GUARD } from "../../../../../services/env";

export default class SignatureCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAlertOn: false,
      company: {},
      select_payment_method: [],
      pabx: [],
      plans: [],
      company_id: "",
      pabx_id: "",
      plan_id: "",
      payment_method_id: "",
      postback_url: "",
      current_period_start: moment(new Date()).format("Y-MM-D"),
      current_period_end: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("Y-MM-D"),
      charges: 1,
      status: "paid",
      date_created: moment(new Date()).format("Y-MM-D"),
      automatically_renew: false,
      validate: {
        company_id: {
          message: "",
          isInvalid: false
        },
        pabx_id: {
          message: "",
          isInvalid: false
        },
        plan_id: {
          message: "",
          isInvalid: false
        },
        payment_method_id: {
          message: "",
          isInvalid: false
        },
        postback_url: {
          message: "",
          isInvalid: false
        },
        current_period_start: {
          message: "",
          isInvalid: false
        },
        current_period_end: {
          message: "",
          isInvalid: false
        },
        charges: {
          message: "",
          isInvalid: false
        },
        status: {
          message: "",
          isInvalid: false
        },
        date_created: {
          message: "",
          isInvalid: false
        },
        automatically_renew: {
          message: "",
          isInvalid: false
        },
      },
      alertMessage: {
        title: "",
        message: "",
      },
    }
    this.first_input = React.createRef();
    this.automatically_renew_true = React.createRef();
    this.automatically_renew_false = React.createRef();
    this.handlerCloseAlert = this.handlerCloseAlert.bind(this);
    this.setDefaultAutomaticallyRenew = this.setDefaultAutomaticallyRenew.bind(this);
    this.getSignatureCreate = this.getSignatureCreate.bind(this);
  }

  componentDidMount() {
    this.getSignatureCreate();
    this.first_input.current.focus();
    this.setDefaultAutomaticallyRenew();
  }

  setDefaultAutomaticallyRenew() {
    if (this.state.automatically_renew) {
      this.automatically_renew_true.current.checked = "checked";
    } else {
      this.automatically_renew_false.current.checked = "checked";
    }
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  handlerCloseAlert() {
    this.setState({
      ...this.state,
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  async handlerClearForm() {
    await this.setState(prevState => {
      return {
        ...prevState,
        pabx_id: "",
        plan_id: "",
        payment_method_id: "",
        current_period_start: moment(new Date()).format("Y-MM-D"),
        current_period_end: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("Y-MM-D"),
        charges: 1,
        status: "paid",
        date_created: moment(new Date()).format("Y-MM-D"),
        automatically_renew: false,
      }
    });
    this.first_input.current.focus();
    this.setDefaultAutomaticallyRenew();
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async getSignatureCreate() {
    this.setState({ isLoadingInputs: true });
    const { params } = this.props.match;
    const { company_id } = params;
    const postback_url = `${URL}/v1/${API_GUARD}/${company_id}/check-payment`
    this.setState({ company_id, postback_url });
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/company/${company_id}/signature/create`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { company, select_payment_method, pabx, plans } = response.data;

      this.setState({ company, select_payment_method, pabx, plans }, () => {
        // console.log("Load combos complete!");
      });
    }
    this.setState({ isLoadingInputs: false });
  }

  async handlerStore(event) {
    event.preventDefault();
    const link = `/v1/${API_GUARD}/signature`;
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        company_id,
        pabx_id,
        plan_id,
        payment_method_id,
        postback_url,
        current_period_start,
        current_period_end,
        charges,
        status,
        date_created,
        automatically_renew
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.post(
          link,
          {
            company_id,
            pabx_id,
            plan_id,
            payment_method_id,
            postback_url,
            current_period_start,
            current_period_end,
            charges,
            status,
            date_created,
            automatically_renew
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.handlerClearForm();
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };
    return (
      <div className={`${styles.container}`}>
        <Header title="Assinaturas" description={
          this.state.company.name ?
            `${this.state.company.name} (${this.state.company.code})` :
            "Carregando..."
        } />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Adicionar assinatura</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerStore(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="date_created">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Criação:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["date_created"].isInvalid}
                      ref={this.first_input}
                      type="date"
                      name="date_created"
                      value={this.state["date_created"]}
                      placeholder="Data da criação da assinatura"
                      onChange={event =>
                        this.handlerChangeInput({ "date_created": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["date_created"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="current_period_start">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Início:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["current_period_start"].isInvalid}
                      type="date"
                      name="current_period_start"
                      value={this.state["current_period_start"]}
                      placeholder="Data do início da assinatura"
                      onChange={event =>
                        this.handlerChangeInput({ "current_period_start": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["current_period_start"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="current_period_end">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Fim:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["current_period_end"].isInvalid}
                      type="date"
                      name="current_period_end"
                      value={this.state["current_period_end"]}
                      placeholder="Data do fim da assinatura"
                      onChange={event =>
                        this.handlerChangeInput({ "current_period_end": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["current_period_end"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="charges">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Número de cobranças:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["charges"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="charges"
                      value={this.state["charges"]}
                      placeholder="Número de cobranças que foram efetuadas na assinatura"
                      onChange={event =>
                        this.handlerChangeInput({ "charges": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["charges"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="status">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Status:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control as="select"
                      onChange={event => {
                        this.handlerChangeInput({ status: event.target.value })
                      }}
                      value={this.state.status}
                    >
                      <option value="paid">Pago</option>
                      <option value="unpaid">Não Pago</option>
                      <option value="trialing">Trial</option>
                      <option value="canceled">Cancelado</option>
                      <option value="ended">Finalizado</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["status"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="pabx_id">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    PABX:
                  </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={false}
                      isLoading={this.state.isLoadingInputs}
                      defaultSelected={[{ id: this.state.pabx_id }]}
                      isInvalid={this.state.validate.pabx_id.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="Selecione um PABX que deseja vincular a assinatura..."
                      options={this.state.pabx}
                      selected={[{ id: this.state.pabx_id }]}
                      onChange={event => {
                        event.length > 0 ?
                          this.handlerChangeInput({
                            pabx_id: event[0].id
                          }) :
                          this.handlerChangeInput({
                            pabx_id: ""
                          })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.pabx_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="plan_id">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Plano:
                  </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={false}
                      isLoading={this.state.isLoadingInputs}
                      defaultSelected={[{ id: this.state.plan_id }]}
                      isInvalid={this.state.validate.plan_id.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="Selecione um plano que deseja vincular a assinatura..."
                      options={this.state.plans}
                      selected={[{ id: this.state.plan_id }]}
                      onChange={event => {
                        event.length > 0 ?
                          this.handlerChangeInput({
                            plan_id: event[0].id
                          }) :
                          this.handlerChangeInput({
                            plan_id: ""
                          })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.plan_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="payment_methods">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Método de pagamento:
                  </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={false}
                      isLoading={this.state.isLoadingInputs}
                      defaultSelected={[{ id: this.state.payment_method_id }]}
                      isInvalid={this.state.validate.payment_method_id.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="Selecione um método de pagamento que deseja vincular a assinatura..."
                      options={this.state.select_payment_method}
                      selected={[{ id: this.state.payment_method_id }]}
                      onChange={event => {
                        event.length > 0 ?
                          this.handlerChangeInput({
                            payment_method_id: event[0].id
                          }) :
                          this.handlerChangeInput({
                            payment_method_id: ""
                          })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.payment_method_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Renovar?
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <Form.Check
                      type="radio"
                      label="Sim"
                      isInvalid={this.state.validate.automatically_renew.isInvalid}
                      name="automatically_renew"
                      value={true}
                      onChange={event => this.handlerChangeInput({ automatically_renew: true })}
                      ref={this.automatically_renew_true}
                    />
                    <Form.Check
                      type="radio"
                      label="Não"
                      isInvalid={this.state.validate.automatically_renew.isInvalid}
                      name="automatically_renew"
                      value={false}
                      ref={this.automatically_renew_false}
                      onChange={event => this.handlerChangeInput({ automatically_renew: false })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.automatically_renew.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Cadastrar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.handlerCloseAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}
