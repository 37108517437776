import React, { Component } from 'react';

import styles from './styles.module.css';

import Header from '../../../components/Header';
import Modal from '../../../components/Modal';
import ModalConfirm from '../../../components/ModalConfirm';
import Button from '../../../components/Buttons/Button';

import api from '../../../services/api';
import { API_GUARD } from '../../../services/env';

export default class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      providers: [],
      isAlertOn: false,
      isExclusionConfirmOn: false,
      marked_provider_id: null,
      modalConfirm: {
        title: "",
        text: ""
      },
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getProviders = this.getProviders.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getProviders();
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleAlert(boolean) {
    this.setState({
      isAlertOn: boolean,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title: title ? title : "",
        message
      }
    });
  }

  async getProviders() {
    try {
      this.toggleIsLoad(true);
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/provider`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { providers } = response.data;
        this.setState({ providers });
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.toggleIsLoad(false);
  }

  toggleConfirm(branch) {
    if (branch) {
      const { id, name } = branch;
      this.setState({
        marked_provider_id: id,
        isExclusionConfirmOn: !this.state.isExclusionConfirmOn,
        modalConfirm: {
          title: "Excluir Fornecedor",
          message: `Tem certeza que deseja excluir o fornecedor: ${name}?`
        }
      });
    } else {
      this.setState({
        marked_branch_id: null,
        isExclusionConfirmOn: false,
        modalConfirm: {
          title: "",
          message: ""
        }
      });
    }
  }

  handlerConfirm() {
    const id = this.state.marked_provider_id;
    this.handlerDestroyProvider(id);
  }

  async handlerDestroyProvider(id) {
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.delete(`/v1/${API_GUARD}/provider/${id}`, {
          headers: { Authorization: "Bearer " + access_token }
        });
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.getProviders()
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          const { errors } = data;
          switch (error.response.status) {
            case 400:
              this.activeAlert({ title: data.title, message: data.message });
              break;
            case 404:
              this.activeAlert({ title: data.title, message: data.message });
              break;
            case 422:
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  handlerHeaderCreateProvider() {
    const { history } = this.props;
    history.push("/admin/fornecedores/adicionar");
  }

  handlerHeaderEditProvider(id) {
    const { history } = this.props;
    history.push(`/admin/fornecedores/${id}/editar`);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  render() {
    return (
      <div className={`${styles.container}`}>
        <Header title="Fornecedores" description="Gerenciamento de Fornecedores" />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de Fornecedores</h2>
            </div>
            <br />
            <div className={styles.tableOption}>
              <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateProvider() }}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Habilitado</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.isLoading ? (
                  <tr>
                    <th colSpan="5">Carregando listagem...</th>
                  </tr>
                ) :
                  (this.state.providers.length <= 0) ? (
                    <tr>
                      <th colSpan="5">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    this.state.providers.map((provider, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{provider.name}</td>
                        <td>{provider.disabled ? "Não" : "Sim"}</td>
                        <td>
                          <div className={styles.tableButton}>
                            <Button text="Editar"
                              type="button"
                              onClick={() => { this.handlerHeaderEditProvider(provider.id) }}
                            />
                            <div className={styles.margin} />
                            <Button text="Excluir"
                              type="button"
                              onClick={() => { this.toggleConfirm(provider) }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
              }
            </tbody>
          </table>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
          <ModalConfirm
            isActive={this.state.isExclusionConfirmOn}
            toggleConfirm={this.toggleConfirm}
            handlerConfirm={() => { this.handlerConfirm(); this.toggleConfirm(null) }}
            title={this.state.modalConfirm.title}
            text={this.state.modalConfirm.message}
          />
        </div>
      </div>
    );
  }
}
