import React, { Component } from 'react';

import styles from './styles.module.css';

import ModalTable from '../../../../components/ModalTable'; 
import Header from '../../../../components/Header';
import Button from '../../../../components/Buttons/Button';

import TableRow from './Table/TableRow';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

export default class Telephone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      telephones: [],
      telephonesInit: [], 
      isAlertOn: false,
      isExclusionConfirmOn: false,
      alertMessage: {
        title: "",
        message: ""
      },
      search:'',
      status:'todos'
    }
    this.getTelephones = this.getTelephones.bind(this);
    this.toggleIsLoad = this.toggleIsLoad.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getTelephones();
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }
  openModal = e => {
    
    this.getHistory(e)

  }
  maskData(e){
    
    let value = e.split(' ')
    value = value[0]
    value = value.split('-')
    value = `${value[2]}/${value[1]}/${value[0]}`
    return value  

  }

  async getHistory(e) {
    try {
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/telephone/${e}/history`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
     
        const history = response.data.history

        let dadosMap = {}
        history.forEach((item) => {
          dadosMap = item 
        })

        const table = 
        <table className={styles.tableModal}>
            <thead className={styles.theadModal}>
              <tr>
                <th>Empresa</th>
                <th>Início</th>
                <th>Fim</th>
              </tr>
            </thead>
            <tbody className={styles.tbodyModal}>
              {dadosMap.company_name ? 
              <tr>
                <td>{dadosMap.company_name}</td>
                <td>{this.maskData(dadosMap.start)}</td>
                <td>{dadosMap.end ? this.maskData(dadosMap.end) : 'Atual'}</td>

              </tr>
              : 
              <tr>
                <td colSpan="3">Sem registros existentes</td>
              </tr>
              }
            </tbody>
          </table>

        this.setState({
          alertMessage: {
            title: "Histórico",
            message: table
          }
        });

        
      
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
           
            
            break;
          default:
            this.error();
        }
      }
    }
    this.setState({
      isAlertOn: true,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async getTelephones() {
    try {
      this.toggleIsLoad(true);
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/telephones`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        // console.log(response.data)
        const { telephones } = response.data;
        this.setState({ telephones });
        this.setState({telephonesInit:telephones})
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.toggleIsLoad(false);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerHeaderCreateTelephone() {
    const { history } = this.props;

    history.push("/admin/PABX/telefones/adicionar");
  }

  search(e){
    let busca
    let statusType = ''
    this.setState({search:e.target.value})
    // if(!e.target.value){
    //   busca = this.state.search
    // }else{
      busca = e.target.value
    // }

    if(this.state.status === 'livre'){
      statusType = false
    }else if(this.state.status === 'emuso'){
      statusType = true
    }
      
    let arr = [] 

    function filterStatus(status){
      return status.in_use === statusType
    }
    function filterSearch(item){
      let quant = busca.length
      return item.ddd.substr(0, quant) === busca
    }


    let filtrado = this.state.telephonesInit.filter(filterStatus)

    arr = filtrado 

    if(this.state.status === 'livre'){
      let arrName = []
      let search = arr.filter(filterSearch)
      if(busca){
        arr.forEach((item)=>{
          if(item.city){
            let quant = busca.length
            if(item.city.name.toLowerCase().substr(0, quant) === busca.toLowerCase()){
              arrName.push(item)
            }
          }
        })
          if(search[0]){
            this.setState({telephones: search})
          }else{
            
            this.setState({telephones: arrName})
          }
        }else{
          this.setState({telephones: arr})
        }
    }
    if(this.state.status === 'emuso'){
      
      let arrName = []
      let search = arr.filter(filterSearch)
      if(busca){
        arr.forEach((item)=>{
          if(item.city){
            let quant = busca.length
            if(item.city.name.toLowerCase().substr(0, quant) === busca.toLowerCase()){
              arrName.push(item)
            }
          }
        })
          if(search[0]){
            this.setState({telephones: search})
          }else{
            
            this.setState({telephones: arrName})
          }
        }else{
          this.setState({telephones: arr})
        }
    }
    if(this.state.status === 'todos'){
      let arrName = []
      let initialArray =  this.state.telephonesInit.filter(filterSearch)
     
      if(busca){
        this.state.telephonesInit.forEach((item)=>{
          if(item.city){
            let quant = busca.length
            if(item.city.name.toLowerCase().substr(0, quant) === busca.toLowerCase()){
              arrName.push(item)
            }
          }
        })
          if(initialArray[0]){
            this.setState({telephones: initialArray})
          }else{
            this.setState({telephones: arrName})
          }
        }else{
          this.setState({telephones: this.state.telephonesInit})
        }

    }
    
    e.preventDefault();
  }

  render() {
    const { isLoading, telephones, status, search } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Telefones" description="Telefones para PABX" />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de Telefones</h2>
            </div>
            <br />
            <div className={styles.tableOption}>
              <form onSubmit={(e) => { this.search(e) }}>
                <div className={styles.form}>
                  <input onChange={(e)=>{this.search(e)}} type='text' value={search}/> 
                  <div className={styles.radio}>
                    <div>
                      <input 
                      type="radio" 
                      id="livre" 
                      name="status" 
                      value="livre"
                      checked={status === "livre" ? 'checked' : '' }
                      onChange={(e)=>{this.setState({status:e.target.value})}}
                      />
                      <label for="livre">Livre</label>
                    </div>
                    <div>
                      <input 
                      type="radio" 
                      id="emuso" 
                      name="status" 
                      value="emuso"
                      checked={status === "emuso" ? 'checked' : '' }
                      onChange={(e)=>{this.setState({status:e.target.value})}}
                      />
                      <label for="emuso">Em uso</label>
                    </div>
                    <div>
                      <input 
                      type="radio" 
                      id="todos" 
                      name="status" 
                      value="todos"
                      checked={status === "todos" ? 'checked' : '' }
                      onChange={(e)=>{this.setState({status:e.target.value})}}
                      />
                      <label for="todos">Todos</label>
                    </div>
                    
                         
                  </div>
                  <div>
                    <button 
                    type="button"
                    className={styles.buttonSearch}
                    onClick={(e) => { this.search(e) }}
                    >Buscar</button>
                    </div>
                </div>  
               

              </form>
              
              <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateTelephone() }}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>#</th>
                <th>DDD</th>
                <th>Telefone</th>
                <th className="d-none d-lg-table-cell">Cidade</th>
                <th className="d-none d-lg-table-cell">Empresa</th>
                <th className="d-none d-lg-table-cell">PABX</th>
                <th className="d-none d-lg-table-cell">Reservado</th>
                <th className="d-none d-lg-table-cell">Status</th>
                <th className="d-none d-lg-table-cell">Histórico</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr>
                    <th colSpan="8">Carregando listagem...</th>
                  </tr>
                ) :
                  !(telephones.length > 0) ? (
                    <tr>
                      <th colSpan="8">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    telephones.map((telephone, key) => (
                      <TableRow
                        history={this.props.history}
                        key={key}
                        item={telephone}
                        id={key}
                        modalActive={this.openModal}
                        getTelephones={this.getTelephones}
                      />
                    ))
              }
            </tbody>
          </table>
          <ModalTable
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
        </div>
      </div>
    );
  }
}
