import React, { Component } from 'react';

// import styles from './styles.module.css';

export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: {
        title: "",
        message: "",
        isAlertOn: false,
      }
    }
  }

  toggleTableLine(id) {
    const tr = document.getElementById(id);
    if (tr.classList.contains('d-none')) {
      tr.classList.remove('d-none');
    } else {
      tr.classList.add('d-none');
    }
  }

  render() {
    return (
      <>
        <tr>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.id + 1
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.item.table
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.column
          }</td>
          <td className="export" onClick={() => this.toggleTableLine(`toggle-${this.props.id}`)}>{
            this.props.item.flag
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.file
          }</td>
        </tr>
        <tr className="d-none"></tr>
        <tr className="d-none" id={`toggle-${this.props.id}`}>
          <td colSpan="11">
            <p><strong>Coluna:</strong> {this.props.item.column}</p>
            <p><strong>Arquivo:</strong> {this.props.item.file}</p>
          </td>
        </tr>
      </>
    );
  }
}
