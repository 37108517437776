import axios from 'axios';
import { URL } from './env';

const api = axios.create({
  // baseURL: 'http://localhost:8001/api',
  baseURL: URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Accept": "application/json",
    "Content-Type": "application/json",
  }
});

export default api;
