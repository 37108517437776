import React, { Component } from 'react';

import styles from './styles.module.css';

import Modal from '../../../../../components/Modal';
import Header from '../../../../../components/Header';
import Button from '../../../../../components/Buttons/Button';

import TableRow from './Table/TableRow';

import api from '../../../../../services/api';
import { API_GUARD } from '../../../../../services/env';

export default class Telephone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pabx_id: "",
      isLoading: true,
      telephones: [],
      isAlertOn: false,
      isExclusionConfirmOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getTelephones = this.getTelephones.bind(this);
    this.toggleIsLoad = this.toggleIsLoad.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {

    const id = this.props.location.state.params;
    this.setState({
      pabx_id: id,
    });
    this.getTelephones(id);
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async getTelephones(pabx_id) {
    try {
      this.toggleIsLoad(true);
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/pabx/${pabx_id}/telephones`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        // console.log(response.data.pabx)
        const { telephones } = response.data.pabx;
        this.setState({ telephones });
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.toggleIsLoad(false);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerHeaderCreateTelephone() {
    const { history } = this.props;
    let pabx_id = this.props.location.state.params;
    history.push("/admin/PABX/numeros/adicionar",{params: pabx_id});
  }

  render() {
    const { isLoading, telephones } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Telefones" description="Telefones PABX" />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de telefones vinculados</h2>
            </div>
            <br />
            <div className={styles.tableOption}>
              <Button text='Voltar' arrowLeft={true}  onClick={this.props.history.goBack}/>
              <Button text="Vincular novo telefone"
                type="button"
                onClick={() => { this.handlerHeaderCreateTelephone() }}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>#</th>
                <th>DDD</th>
                <th>Telefone</th>
                <th className="d-none d-lg-table-cell">Cidade</th>
                <th className="d-none d-lg-table-cell">Número principal</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr>
                    <th colSpan="6">Carregando listagem...</th>
                  </tr>
                ) :
                  !(telephones.length > 0) ? (
                    <tr>
                      <th colSpan="6">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    telephones.map((telephone, key) => (
                      <TableRow
                        history={this.props.history}
                        key={key}
                        item={telephone}
                        id={key}
                      />
                    ))
              }
            </tbody>
          </table>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
        </div>
      </div>
    );
  }
}
