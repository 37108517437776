import React, { useState, useEffect } from "react";

import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../store/actions/user";

import api from "../../services/api";
import { API_GUARD } from "../../services/env";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import NotFound from "../Errors/NotFound";

import Dashboard from "../../pages/User/Dashboard";
import Company from "../../pages/User/Company";
import EditCompany from "../../pages/User/Company/EditCompany";
import Recharge from "../../pages/User/Company/Recharge";
import Signature from "../../pages/User/Company/Signature";
import SignatureCreate from "../../pages/User/Company/Signature/SignatureCreate";
import SignatureEdit from "../../pages/User/Company/Signature/SignatureEdit";

import Config from "../../pages/User/Config";
// import SystemSettings from "../../pages/User/Admin/SystemSettings";
// import Users from "../../pages/User/Admin/Users";

// import Adverts from "../../pages/User/PABX/Adverts";
// import AudioManagement from "../../pages/User/PABX/AudioManagement";
// import AutoAnswer from "../../pages/User/PABX/AutoAnswer";
import Branches from "../../pages/User/PABX/Branches";
import CreateBranch from "../../pages/User/PABX/Branches/CreateBranch";
import EditBranch from "../../pages/User/PABX/Branches/EditBranch";
// import CallQueues from "../../pages/User/PABX/CallQueues";
// import Departments from "../../pages/User/PABX/Departments";
// import InputRoutes from "../../pages/User/PABX/InputRoutes";
// import OutputRoutes from "../../pages/User/PABX/OutputRoutes";
// import RoutesGroup from "../../pages/User/PABX/RoutesGroup";
// import ScheduleConditions from "../../pages/User/PABX/ScheduleConditions";
// import StandbyAudio from "../../pages/User/PABX/StandbyAudio";
// import Trunkes from "../../pages/User/PABX/Trunkes";

// import BranchesStatus from "../../pages/User/Reception/BranchesStatus";
// import OnlineCalls from "../../pages/User/Reception/OnlineCalls";
// import QueueDetails from "../../pages/User/Reception/QueueDetails";

// import CallReporting from "../../pages/User/Reports/CallReporting";
// import MinuteChart from "../../pages/User/Reports/MinuteChart";

// import Status from "../../pages/User/Status";
import Plans from '../../pages/User/Plans';
import CreatePlan from '../../pages/User/Plans/CreatePlan';
import EditPlan from '../../pages/User/Plans/EditPlan';

import AsteriskFlags from '../../pages/User/PABX/AsteriskFlags';
import Telephone from '../../pages/User/PABX/Telephone';
import CreateTelephone from '../../pages/User/PABX/Telephone/CreateTelephone';
import EditTelephone from '../../pages/User/PABX/Telephone/EditTelephone';
import PABX from '../../pages/User/PABX/PABX';
import Users from "../../pages/User/PABX/Admin/Users";
import EditUser from "../../pages/User/PABX/Admin/Users/EditUser";
import CreateUser from "../../pages/User/PABX/Admin/Users/CreateUser";

import TelephonePABX from '../../pages/User/PABX/PABX/TelephonePABX';
import CreateTelephonePABX from '../../pages/User/PABX/PABX/TelephonePABX/CreateTelephone';

import Report from "../../pages/User/Report/ReportPhones"
import ReportPhones from "../../pages/User/Report/ReportPhones"

import styles from "./style.module.css";

import Cities from '../../pages/User/Cities';
import CreateCity from '../../pages/User/Cities/CreateCity';
import EditCity from '../../pages/User/Cities/EditCity';
import Providers from "../../pages/User/Providers";
import CreateProvider from "../../pages/User/Providers/CreateProvider";
import EditProvider from "../../pages/User/Providers/EditProvider";
import Footer from "../../components/Footer";


function User({ history, updateUser }) {
  // const [authenticated, setAuthenticated] = useState(true);
  const [routes, setRoutes] = useState([]);
  const [click, setClick] = useState(false);

  useEffect(() => {
    checkIfTokenExists();
    setRoutes([
      {
        path: "/admin/dashboard",
        component: Dashboard,
        exact: false
      },
      {
        path: "/admin/home",
        component: Dashboard,
        exact: false
      },
      {
        path: "/admin/empresas",
        component: Company,
        exact: true
      },
      {
        path: "/admin/empresa/:id/editar",
        component: EditCompany,
        exact: true
      },
      {
        path: "/admin/company/:id/adicionar-creditos",
        component: Recharge,
        exact: true
      },
      {
        path: "/admin/company/:company_id/assinaturas",
        component: Signature,
        exact: true
      },
      {
        path: "/admin/company/:company_id/assinaturas/adicionar",
        component: SignatureCreate,
        exact: true
      },
      {
        path: "/admin/company/:company_id/assinaturas/:signature_id/editar",
        component: SignatureEdit,
        exact: true
      },
      // {
      //   path: "/admin/system-settings",
      //   component: SystemSettings,
      //   exact: false
      // },
      // {
      //   path: "/admin/users",
      //   component: Users,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/anuncios",
      //   component: Adverts,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/gerenciamento-de-audio",
      //   component: AudioManagement,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/ura-de-atendimento",
      //   component: AutoAnswer,
      //   exact: false
      // },
      {
        path: "/admin/PABX/ramais",
        component: Branches,
        exact: true
      },
      {
        path: "/admin/PABX/ramais/adicionar",
        component: CreateBranch,
        exact: true
      },
      {
        path: "/admin/usuarios",
        component: Users,
        exact: false
      },
      {
        path: "/admin/PABX/usuarios/:id/editar",
        component: EditUser,
        exact: true
      },
      {
        path: "/admin/PABX/usuarios/adicionar",
        component: CreateUser,
        exact: true
      },
      {
        path: "/admin/PABX/ramais/:id/editar",
        component: EditBranch,
        exact: true
      },
      {
        path: "/admin/planos",
        component: Plans,
        exact: true
      },
      {
        path: "/admin/planos/adicionar",
        component: CreatePlan,
        exact: true
      },
      {
        path: "/admin/planos/:id/editar",
        component: EditPlan,
        exact: true
      },
      {
        path: "/admin/cidades",
        component: Cities,
        exact: true
      },
      {
        path: "/admin/cidades/adicionar",
        component: CreateCity,
        exact: true
      },
      {
        path: "/admin/cidades/:id/editar",
        component: EditCity,
        exact: true
      },
      {
        path: "/admin/fornecedores",
        component: Providers,
        exact: true
      },
      {
        path: "/admin/fornecedores/adicionar",
        component: CreateProvider,
        exact: true
      },
      {
        path: "/admin/fornecedores/:id/editar",
        component: EditProvider,
        exact: true
      },
      {
        path: "/admin/PABX/asterisk-flags",
        component: AsteriskFlags,
        exact: true
      },
      {
        path: "/admin/PABX/telefones",
        component: Telephone,
        exact: true
      },
      {
        path: "/admin/PABX/telefones/adicionar",
        component: CreateTelephone,
        exact: true
      },
      {
        path: "/admin/PABX/telefones/:id/editar",
        component: EditTelephone,
        exact: true
      },
      {
        path: "/admin/PABX/numeros",
        component: TelephonePABX,
        exact: true
      },
      {
        path: "/admin/PABX/numeros/adicionar",
        component: CreateTelephonePABX,
        exact: true
      },
      {
        path: "/admin/PABX/PABX",
        component: PABX,
        exact: true
      },
      {
        path: "/admin/configuracoes",
        component: Config,
        exact: true
      },
      {
        path: "/admin/relatorio",
        component: Report,
        exact: true
      },
      {
        path: "/admin/relatorio/telefones",
        component: ReportPhones,
        exact: true
      },
      // {
      //   path: "/admin/PABX/filas-de-atendimento",
      //   component: CallQueues,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/departamentos",
      //   component: Departments,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/rotas-de-entrada",
      //   component: InputRoutes,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/rotas-de-saida",
      //   component: OutputRoutes,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/grupos-de-rotas",
      //   component: RoutesGroup,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/condicoes-de-horarios",
      //   component: ScheduleConditions,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/audio-de-espera",
      //   component: StandbyAudio,
      //   exact: false
      // },
      // {
      //   path: "/admin/PABX/troncos",
      //   component: Trunkes,
      //   exact: false
      // },
      // {
      //   path: "/admin/recepcao/status-de-ramais",
      //   component: BranchesStatus,
      //   exact: false
      // },
      // {
      //   path: "/admin/recepcao/chamadas-online",
      //   component: OnlineCalls,
      //   exact: false
      // },
      // {
      //   path: "/admin/recepcao/detalhes-fila",
      //   component: QueueDetails,
      //   exact: false
      // },
      // {
      //   path: "/admin/relatorios/relatorio-chamadas",
      //   component: CallReporting,
      //   exact: false
      // },
      // {
      //   path: "/admin/relatorios/grafico-minutos",
      //   component: MinuteChart,
      //   exact: false
      // },
      // {
      //   path: "/admin/status",
      //   component: Status,
      //   exact: false
      // },
      {
        component: NotFound
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkIfTokenExists() {
    const access_token = await localStorage.getItem("access_token");

    if (access_token) {
      // setAuthenticated(true);
      try {
        const response = await api.post(`/v1/${API_GUARD}/auth/me`, null, {
          headers: { Authorization: "Bearer " + access_token }
        });

        if (response.status === 200) {
          const { name, email } = response.data;
          // setAuthenticated(true);
          updateUser(name, email);
          return;
        } else {
          // setAuthenticated(false);
          history.push("/");
          return;
        }
      } catch (error) {
        // console.log(error);
        localStorage.removeItem("access_token");
        // setAuthenticated(false);
        history.push("/");
      }
    } else {
      // setAuthenticated(false);
      history.push("/");
    }
  }

  return (
    <>
      <div className={styles.container}>
        <Navbar history={history} className={styles.navbar} listenerClick={click} />
        <Sidebar history={history} className={styles.sidebar} onClick={() => setClick(!click)} />
        <div className={styles.switch} onClick={() => setClick(!click)}>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Switch>
        </div>
        <Footer/>      
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
