import React, { Component } from 'react';
import { FaHistory } from 'react-icons/fa';

import api from '../../../../../../services/api';
import { API_GUARD } from '../../../../../../services/env';

import styles from './styles.module.css';

import ModalConfirm from "../../../../../../components/ModalConfirm";
import Modal from "../../../../../../components/Modal";

export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: "",
      },
      isConfirmActive: false,
      isDeleteConfirmActive: false,
    }
    
  }

  handlerActionSelectChange(value, id) {
    switch (value) {
      case "edit":
        this.handlerHeaderEditTelephone(id);
        break;
      case "delete":
        this.setState({
          isConfirmActive: true,
        });
        break;
      default:

    }
  }

  handlerHeaderEditTelephone(id) {
    const { history } = this.props;

    history.push(`/admin/PABX/telefones/${id}/editar`);
  }

  async handlerHeaderDeleteTelephone(id) {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.delete(`/v1/${API_GUARD}/telephones/${id}`, {
        headers: { Authorization: "Bearer " + access_token }
      });

      this.setState({
        isConfirmActive: false,
      });

      if (response.status && response.status === 200) {
        const { title, message } = response.data;
        
        this.setState({
          isAlertOn: true,
          alertMessage: {
            title,
            message,
          }
        });

        setTimeout(() => {
          this.props.getTelephones();
        }, 2500)
      }

    } catch (error) {

      this.setState({
        alertMessage: {
          title: "Ops...",
          message: "Aconteceu algo de errado. Por favor tente mais tarde!",
        }
      });

      if (error.response) {
        const { title, message } = error.response.data;
  
        this.setState({
          alertMessage: {
            title: title || "Ops...",
            message: message || "Aconteceu algo de errado. Por favor tente mais tarde!",
          }
        });

        if (error.response.status == 403) {
          this.setState({isDeleteConfirmActive: true});

          return;
        }
      }
      
      console.log(error);

      this.setState({
        isAlertOn: true,
      });
    }
  }

  async deleteConfirmed(id) {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.delete(`/v1/${API_GUARD}/telephones/${id}?confirm_delete=true`, {
        headers: { Authorization: "Bearer " + access_token }
      });

      this.setState({
        isConfirmActive: false,
        isDeleteConfirmActive: false,
      });

      if (response.status && response.status === 200) {
        const { title, message } = response.data;
        
        this.setState({
          isAlertOn: true,
          alertMessage: {
            title,
            message,
          }
        });

        setTimeout(() => {
          this.props.getTelephones();
        }, 2500)
      }

    } catch (error) {

      this.setState({
        alertMessage: {
          title: "Ops...",
          message: "Aconteceu algo de errado. Por favor tente mais tarde!",
        }
      });

      if (error.response) {
        const { title, message } = error.response.data;
  
        this.setState({
          alertMessage: {
            title: title || "Ops...",
            message: message || "Aconteceu algo de errado. Por favor tente mais tarde!",
          }
        });
      }
      
      console.log(error);

      this.setState({
        isAlertOn: true,
      });
    }
  }

  open = (e) => {
    this.props.modalActive(e);
  }

  render() {
    return (
      <>
        <tr>
          <td className="export">{
            this.props.id + 1
          }</td>
          <td className="export">{
            this.props.item.ddd
          }</td>
          <td className="export">{
            this.props.item.number
          }</td>
          <td className="export">{
            this.props.item.city ? `${this.props.item.city.name}` : "Não atribuído"
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.company ? `${this.props.item.company.name} (${this.props.item.company.code})` : "Não atribuído"
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.pabx ? `${this.props.item.pabx.name} (${this.props.item.pabx.code})` : "Não atribuído"
          }</td>
           <td className="export d-none d-lg-table-cell">{
            this.props.item.reserved ? 'Sim' : 'Não' 
          }</td>
          <td className="export d-none d-lg-table-cell">{
            this.props.item.in_use ? 'Em uso' : 'Livre' 
          }</td>
          <td className="export d-none d-lg-table-cell">
              <FaHistory
                className={styles.icon}
                onClick={()=>{this.open(this.props.item.id)}}
              />
          </td>
          <td className={`export ${styles.select}`}>{
            !(this.props.item.pabx || this.props.item.company) ?
              (
                <select name="action" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
                  <option value="">Nenhuma</option>
                  <option value="edit">Editar</option>
                  <option value="delete">Excluir</option>
                </select>
              )
              : (
                <select aria-readonly name="action" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
                  <option value="">Nenhuma</option>
                  <option value="edit">Editar</option>
                </select>
              )

          }</td>
        </tr>
        <tr className="d-none"></tr>
        <tr className="d-none" id={`toggle-${this.props.id}`}>
          <td colSpan="8">
            <p><strong>Empresa:</strong> {this.props.item.company ? `${this.props.item.company.name} (${this.props.item.company.code})` : "Não atribuído"}</p>
            <p><strong>PABX:</strong> {this.props.item.pabx ? `${this.props.item.pabx.name} (${this.props.item.pabx.code})` : "Não atribuído"}</p>
            <p><strong>Reservado:</strong> {this.props.item.reserved }</p>
            <p><strong>Status:</strong> {this.props.item.in_use ? "Em uso" : "Livre" }</p>
            <p><strong>Histórico:</strong> <FaHistory className={styles.icon} onClick={()=>{this.open(this.props.item.id)}}/></p>
          </td>
        </tr>
        <ModalConfirm
          isActive={this.state.isConfirmActive}
          title= "Excluir telefone"
          text={`Deseja mesmo excluir o telefone "${this.props.item.number}"`}
          handlerConfirm={() => {this.handlerHeaderDeleteTelephone(this.props.item.id)}}
          toggleConfirm={() => {
            this.setState({isConfirmActive: false});          
          }}
        
        />
        <ModalConfirm
          isActive={this.state.isDeleteConfirmActive}
          title= {`Número ${this.props.item.number} contém histórico de utilização!`}
          text={`Deseja prosseguir com a exclusão?"`}
          handlerConfirm={() => {this.deleteConfirmed(this.props.item.id)}}
          toggleConfirm={() => {
            this.setState({
              ...this.state,
              isConfirmActive: false,
              isDeleteConfirmActive: false,
            });          
          }}
        
        />
        <Modal
          isActive={this.state.isAlertOn}
          title={this.state.alertMessage.title}
          text={this.state.alertMessage.message} 
          toggleAlert={() => {
            this.setState({isAlertOn: false}); 
          }}       
        />
      </>
    );
  }
}
