import React, { Component } from 'react';

import styles from './styles.css';

import Button from '../../../../../components/Buttons/Button';
import Modal from '../../../../../components/Modal';
import ModalConfirm from '../../../../../components/ModalConfirm';
import Header from '../../../../../components/Header';

import api from '../../../../../services/api';
import { API_GUARD } from '../../../../../services/env';
import { Form } from "react-bootstrap";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      users: [],
      usersFilto: [],
      pabx_filter: [],
      isAlertOn: false,
      isFiltro:false,
      isExclusionConfirmOn: false,
      marked_branch_id: null,
      modalConfirm: {
        title: "",
        text: ""
      },
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getUsers = this.getUsers.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.handlerConfirm = this.handlerConfirm.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }

  toggleConfirm(user) {
    if (user) {
      const { id, name } = user;
      this.setState({
        marked_user_id: id,
        isExclusionConfirmOn: !this.state.isExclusionConfirmOn,
        modalConfirm: {
          title: "Excluir Usuário",
          message: `Tem certeza que deseja excluir o usuário ${name}?`
        }
      });
    } else {
      this.setState({
        marked_branch_id: null,
        isExclusionConfirmOn: false,
        modalConfirm: {
          title: "",
          message: ""
        }
      });
    }
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  handlerChangeInput(value) {
    const data = this.state.pabx_filter;
    const searchValue = value.search.toLowerCase();

    const filtered_array = data.filter((item)=>{
      const name = item.name.toLowerCase();
      const email = item.email.toLowerCase();
      const companyCode = item.company.code.toString();
      const companyName = item.company.name.toLowerCase();

      if (name.indexOf(searchValue) >= 0) {
        return true;
      }

      if (email.indexOf(searchValue) >= 0) {
        return true;
      }

      if (companyCode.indexOf(searchValue) >= 0) {
        return true;
      }

      if (companyName.indexOf(searchValue) >= 0) {
        return true;
      }


    });
    this.setState({pabx:filtered_array})
    const  usersFilto = filtered_array;
    this.setState({ users:[] });
    this.setState({  usersFilto });

    this.setState({ isFiltro: true})
    
    if (value.length < 1) {
      
      this.setState({ isFiltro: false})
    }

  }

  async getUsers() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.get(`/v1/${API_GUARD}/company_users`, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { users } = response.data;
        this.setState({ users });
        this.setState({ isFiltro: false})
        this.setState({ usersFilto:[] });
        this.setState({pabx_filter:users})
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.setState({ isLoading: false });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerHeaderCreateUser() {
    const { history } = this.props;

    history.push("/admin/PABX/usuarios/adicionar");
  }

  handlerHeaderEditUser(idUser) {
    const { history } = this.props;

    history.push(`/admin/PABX/usuarios/${idUser}/editar`);
  }

  handlerConfirm() {
    const id = this.state.marked_user_id;
    this.handlerDestroyUser(id);
  }

  phoneMask(num){
      let value = num
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
           
      return value
  }

  async handlerDestroyUser(id) {

    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.delete(`/v1/${API_GUARD}/company_users/${id}`, {
          headers: { Authorization: "Bearer " + access_token }
        });
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
          // console.log(response)
        }
        this.getUsers()
       
      } catch (error) {
        // console.log(error)
        if (error.response) {
          switch (error.response.status) {
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const { isLoading, users, usersFilto, isFiltro } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Usuários" description="Gerenciamento de Usuários" />
        <div className="body shadowBox tableHeaderMin">
          <div className="tableHeader">
            <div className="tableTitle">
              <h2>Listagem de Usuários</h2>
            </div>
            <div className="tableOption">
              <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateUser() }}
              />
            </div>
          </div>
          <div className="search-user">
            <Form.Label >
                Pesquisar:
              </Form.Label>
              <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nome da empresa"
                  onChange={event =>
                    this.handlerChangeInput({ search: event.target.value })
                  }
                />
            </div>
          <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Empresa</th>
                <th>Telefones</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {!isFiltro?
                isLoading ? (
                  <tr>
                    <th colSpan="5">Carregando listagem...</th>
                  </tr>
                ) :
                  (users.length < 1) ? (
                    <tr>
                      <th colSpan="5">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    users.map((user, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.company.name}</td>
                        <td>{user.phone ? this.phoneMask(user.phone) : "Não cadastrado"}</td>
                        <td>
                          <div className="tableButton">
                            <Button text="Editar"
                              type="button"
                              id={user.id}
                              onClick={() => { this.handlerHeaderEditUser(user.id) }}
                            />
                            <Button text="Excluir"
                              type="button"
                              onClick={() => { this.toggleConfirm(user) }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                    
              :
              isLoading ? (
                <tr>
                  <th colSpan="5">Carregando listagem...</th>
                </tr>
              ) :
                ( usersFilto.length < 1) ? (
                  <tr>
                    <th colSpan="5">Nenhum registro encontrado.</th>
                  </tr>
                ) :
                   usersFilto.map((user, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.company.name}</td>
                      <td>
                        <div className="tableButton">
                          <button className="style_btn__1eyAP style_btnDefault__2AAmz" onClick={() => { this.handlerHeaderEditUser(user.id) }}>Editar</button>
                          <button className="style_btn__1eyAP style_btnDefault__2AAmz" onClick={() => { this.toggleConfirm(user) }}>Excluir</button>
                        </div>
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
          </div>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
          <ModalConfirm
            isActive={this.state.isExclusionConfirmOn}
            toggleConfirm={this.toggleConfirm}
            handlerConfirm={() => { this.handlerConfirm(); this.toggleConfirm(null) }}
            title={this.state.modalConfirm.title}
            text={this.state.modalConfirm.message}
          />
        </div>
      </div>
    );
  }
}
