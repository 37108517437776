import React, { Component } from 'react';

import styles from './style.module.css';
import styleM from '../../Modal/style.module.css';
import Modal from "../../../components/ModalAlert";
import { Form, Row } from "react-bootstrap";
import api from "../../../services/api";
import { API_GUARD } from "../../../services/env";
import ButtonReverse from '../../Buttons/ButtonReverse/style.module.css';


export default class CardAction extends Component {
  
constructor(props) {
  super(props);
  this.state = {
    max_branches: "",
    open:"",
    id: "",
    isAlertOn: false,
    alertMessage: {
      title: "",
      message: ""
    },
  };
  this.closeModal = this.closeModal.bind(this);
  this.toggleAlert = this.toggleAlert.bind(this);
}
toggleAlert() {
  this.setState({
    isAlertOn: !this.state.isAlertOn
  });
}

activeAlert(alertMessage) {
  const { title, message } = alertMessage;
  this.setState({
    ...this.state,
    isAlertOn: true,
    alertMessage: {
      title,
      message
    }
  });
}
  handlerActionSelectChange(value, id) {
    switch (value) {
      case "edit":
        this.handlerHeaderEditTelephone(id);
        break;
      case "block":
        this.props.handlerBlockPabx(id);
        break;
      case "unblock":
        this.props.handlerUnblockPabx(id);
        break;
      case "view":
        this.props.handlerHeaderListTelephone(id);
      break;
      case "ramais":
        this.props.handlerHeaderListRamais(id);
      break;
      case "maxRamais":
        this.maxRamais(id);
      break;
      default:

    }
  }
  maxRamais(id){
    this.setState({
      open: true,
      id: id
    });
  }

    async handlerUpdate(event) {
      this.setState({
        open: false,
      });
      event.preventDefault();
        // const { history } = this.props;
        const {
          max_branches
        } = this.state;
        try {
          const access_token = await localStorage.getItem("access_token");
          const response = await api.put(
            `/v1/${API_GUARD}/pabx/${this.state.id}/max_branches`,
            {
              max_branches,
            },
            {
              headers: { Authorization: "Bearer " + access_token }
            }
          );
          if (response.status && response.status === 200) {
            const { title, message } = response.data;
            this.activeAlert({ title, message });
            setTimeout(() =>{
            window.location.reload();
          },1200)
          }
        } catch (error) {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                const { title, message } = error.response.data;
                this.activeAlert({ title, message });
                break;
              case 422:
                const { errors } = error.response.data;
                for (let key in errors) {
                  this.activeAlert({ tilte:'Erro!', message: errors[key] });
                  setTimeout(() =>{
                  window.location.reload();
                },1500)
                }
                break;
              default:
                this.error();
            }
          }
        }
    }
    error() {
      const alert = {
        title: "Ops... Algo deu errado.",
        message: "Não foi possível completar sua requisição."
      }
      this.activeAlert(alert);
    }
  closeModal(){
    this.setState({
      open: false,
    });
  }

  handlerHeaderEditTelephone(id) {
    const { history } = this.props;
    history.push(`/admin/PABX/telefones/${id}/editar`);
  }

  handlerChangeInput(value) {
    this.setState({
      max_branches: value.max_branches,
    });
  }

  render() {
    const { content, title, description,ddd, qtdRamal} = this.props;
    return (
      <>
        <div className={`${styles.container} ${styles.shadowBox}`}>
          <div className={styles.header}>
          <div className={styles.info}>
            <h2 className={styles.title}>
              {title}
            </h2>
            <h6>
            <small className={styles.discription}>
                Empresa: {description}
              </small>
            </h6>
          </div>  
          <div className={styles.action}>
            <div className={styles.left}>
            {
              ddd ? <small className={styles.ddd}>DDD:{ddd}</small> : ''
            }

          <h6 className={styles.status}>
            Status: {
              this.props.blocked ? "Bloqueado" : "Desbloqueado"
            }
          </h6>
          <h6 className={styles.status}>
            QTD Ramais Contratados :{qtdRamal}
          </h6>
          </div>
          <div className={styles.right}>
            <h6>Ações:</h6>
            <select name="action" value="" onChange={(event) => this.handlerActionSelectChange(event.target.value, this.props.item.id)} id="action" className={`form-control ${styles.select}`}>
            <option value="">Nenhuma</option>
            {!this.props.blocked ?
              (<option value="block">Bloquear</option>)
              : (<option value="unblock">Desbloquear</option>)
            }
            <option value="view">Ver telefones</option>
            <option value="ramais">Gerenciar Ramais</option>
            <option value="maxRamais">Alterar quantidade de ramais</option>
            </select>
          </div>
          
            
            
            </div>
          </div>
          <div className={styles.body}>
            QTD Ramais em uso:
            {content}
          </div>
          {this.state.open?
          <div className={styleM.modal}>
                <div className={styleM.modalContainer}>
                  <div className={styleM.modalHeader}>
                    <h3>Alterar quantidade de ramais</h3>
                  </div>
                  <div className={styleM.modalBody+ " " +styleM.modalContainerS}>
                  <p>Qual a quantidade de ramal desejada?</p>
                  <div>
                  <form
                    onSubmit={event => this.handlerUpdate(event)}
                    className={styles.form}
                    ref="form"
                  >
                  <Form.Group as={Row} controlId="formHorizontalName">
                      <Form.Control
                        ref={this.max_branches}
                        type="tel"
                        name="max_branches"
                        required
                        value={this.state.max_branches}
                        placeholder="Número de ramais desejados"
                        onChange={event =>
                          this.handlerChangeInput({ max_branches: event.target.value })
                        }
                      />
                  </Form.Group>
                  <div className={styleM.modalFooterS}>
                  <button
                    onClick={this.closeModal}
                    className={ButtonReverse.btn + " " + ButtonReverse.btnReverse + " " + ButtonReverse.btnReverseMr}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className={ButtonReverse.btn + " " + ButtonReverse.btnReverse}
                  >
                    Alterar
                  </button>
                  </div>
                </form>
                  </div>
                  </div>
             
                </div>
              </div>
              :''}
               <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.toggleAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
        </div>
      </>
    );
  }
}
