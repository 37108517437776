import React, { Component } from 'react';

import styles from './styles.module.css';

import Modal from '../../../../components/Modal';
import Header from '../../../../components/Header';

import TableRow from './Table/TableRow';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

export default class AsteriskFlags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      asterisk_flags: [],
      isAlertOn: false,
      isExclusionConfirmOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getTags = this.getTags.bind(this);
    this.toggleIsLoad = this.toggleIsLoad.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getTags();
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async getTags() {
    try {
      this.toggleIsLoad(true);
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/asterisk_flag`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { asterisk_flags } = response.data;
        this.setState({ asterisk_flags });
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.toggleIsLoad(false);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  render() {
    const { isLoading, asterisk_flags } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Flags" description="Marcadores para Asterisk." />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de Tags</h2>
            </div>
            <br />
            <div className={styles.tableOption}>

            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>#</th>
                <th>Tabela</th>
                <th className="d-none d-lg-table-cell">Coluna</th>
                <th>Flag</th>
                <th className="d-none d-lg-table-cell">Arquivo</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr>
                    <th colSpan="5">Carregando listagem...</th>
                  </tr>
                ) :
                  !(asterisk_flags.length > 0) ? (
                    <tr>
                      <th colSpan="5">Nenhum registro encontrado.</th>
                    </tr>
                  ) :
                    asterisk_flags.map((tag, key) => (
                      <TableRow
                        key={key}
                        item={tag}
                        id={key}
                      />
                    ))
              }
            </tbody>
          </table>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
        </div>
      </div>
    );
  }
}
