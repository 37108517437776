import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../store/actions/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './style.module.css';

import Avatar from '../../assets/images/default-avatar.png';
import { MATCH_SPACES } from '../../services/regex';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userOptionsDisplay: false,
      name: "Carregando ..."
    }
  }

  componentDidMount() {
    const { name } = this.props.user;
    if (name) {
      this.setFirstAndLastName(name);
    } else {
      this.setState({ name });
    }
  }

  setFirstAndLastName(name) {
    const nameArray = name.match(MATCH_SPACES);
    const firstAndLastName = nameArray.length > 1 ? `${nameArray[0]} ${nameArray[nameArray.length - 1]}` : nameArray[0];

    this.setState({ name: firstAndLastName });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listenerClick !== this.props.listenerClick) {
      this.setState({ userOptionsDisplay: false });
    }

    if (prevProps.user.name !== this.props.user.name) {
      const { name } = this.props.user;
      if (name) {
        this.setFirstAndLastName(name);
      } else {
        this.setState({ name });
      }

    }
  }

  async handlerLogout() {
    const { history } = this.props;
    await localStorage.removeItem("access_token");
    history.push("/");
  }

  handlerToggleUserOptions() {
    this.setState({
      userOptionsDisplay: !this.state.userOptionsDisplay
    })
  }

  render() {
    const { className } = this.props;
    return (
      <>
        <nav className={`${className} ${styles.navbar}`}>
          <div className={styles.navitem}
            onClick={() => this.handlerToggleUserOptions()} >
            <div className={styles.username}>
              {this.state.name ? this.state.name : "Carregando ..."} &ensp;
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={styles.icon}
                />
            </div>
            <div>
              &emsp;<img src={Avatar} alt="Avata" />
            </div>
            <ul className={
              this.state.userOptionsDisplay ?
                `${styles.list} ${styles.displayListItems}`
                : `${styles.list} ${styles.hiddenListItems}`
            }>
              <li className={`${styles.listItem}`}
                onClick={() => this.handlerLogout()}>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "5px" }} />Sair
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);