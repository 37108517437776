import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import styles from './style.module.css';

export default class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      type: "button",
      btnClass:"",
      arrowLeft: false,
    };
    this.onClick = this.props.onClick.bind(this);
  }

  componentDidMount() {
    const { text, type, btnClass,arrowLeft } = this.props;
    type ?
    this.setState({ text, type, btnClass, arrowLeft })
    : this.setState({ text, btnClass, arrowLeft })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      const { text } = this.props;
      this.setState({ text });
    }
  }
  render() {
    return (
      <button
        className={`${styles.btn} ${styles.btnDefault} ${this.state.btnClass}`}
        onClick={() => { this.onClick() }}
        type={this.state.type}
      >
        {
          this.state.arrowLeft && (<FontAwesomeIcon className='btn-back-arrow-left' icon={faArrowLeft}  />)
        }
        {this.state.text}
      </button>
    );
  }
}
