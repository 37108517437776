import React, { Component } from 'react';

import styles from "./styles.module.css";

import Header from "../../../components/Header";
import ButtonLoading from "../../../components/Buttons/ButtonLoading";
import Modal from "../../../components/Modal";
import { integerToBRLCurrency, BRLCurrencyToInt } from '../../../services/helpers';

import { Form, Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { API_GUARD } from "../../../services/env";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingUpdatePabx: false,
      isAlertOn: false,
      admin_login_attempts: null,
      company_login_attempts: null,
      branch_login_attempts: null,
      initial_companies_available_balance: null,
      isLoadingInputs: true,
      validate: {
        admin_login_attempts: {
          message: "",
          isInvalid: false
        },
        company_login_attempts: {
          message: "",
          isInvalid: false
        },
        branch_login_attempts: {
          message: "",
          isInvalid: false
        },
        initial_companies_available_balance: {
          message: "",
          isInvalid: false
        },
      },
      alertMessage: {
        title: "",
        message: "",
      },
    };
    this.handlerCloseAlert = this.handlerCloseAlert.bind(this);
    this.getEditFlexipConfiguration = this.getEditFlexipConfiguration.bind(this);
  }

  componentDidMount() {
    this.getEditFlexipConfiguration();
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerCloseAlert() {
    this.setState({
      ...this.state,
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    });
  }

  toggleIsLoadUpdatePabx(boolean) {
    this.setState({ isLoadingUpdatePabx: boolean });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async handlerStore(event) {
    event.preventDefault();
    const link = `/v1/${API_GUARD}/config/pabx/update-all`;
    if (!this.state.isLoadingUpdatePabx) {
      this.toggleIsLoadUpdatePabx(true);

      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.post(
          link,
          {

          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.handlerClearForm();
        this.toggleIsLoadUpdatePabx(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoadUpdatePabx(false);
      }
    }
  }

  async getEditFlexipConfiguration() {
    this.setState({ isLoadingInputs: true });
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/admin/config/flexip-config/1/edit`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { config } = response.data;
      const { admin_login_attempts, company_login_attempts, branch_login_attempts, initial_companies_available_balance } = config;

      this.setState({ admin_login_attempts, company_login_attempts, branch_login_attempts, initial_companies_available_balance });
    }
    this.setState({ isLoadingInputs: false });
  }

  async handlerUpdateFlexipConfiguration(event) {
    event.preventDefault();
    const link = `/v1/admin/config/flexip-config/1`;
    if (!this.state.isLoading && !this.state.isLoadingInputs) {
      this.toggleIsLoad(true);

      const {
        admin_login_attempts,
        company_login_attempts,
        branch_login_attempts,
        initial_companies_available_balance
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.put(
          link,
          {
            admin_login_attempts,
            company_login_attempts,
            branch_login_attempts,
            initial_companies_available_balance
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 2 },
      lg: { offset: 2, span: 2 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 6 },
      lg: { span: 6 }
    };
    return (
      <div className={`${styles.container}`}>
        <Header title="Configurações" description="Configurações do sistema" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Configurações Flexip</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerUpdateFlexipConfiguration(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="admin_login_attempts">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    <strong>Tentativar login Admin WFG:</strong>
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.admin_login_attempts}
                      isInvalid={this.state.validate.admin_login_attempts.isInvalid}
                      type="number"
                      name="admin_login_attempts"
                      value={this.state.admin_login_attempts}
                      placeholder=""
                      onChange={event =>
                        this.handlerChangeInput({ admin_login_attempts: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.admin_login_attempts.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="company_login_attempts">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    <strong>Tentativar login Admin PABX:</strong>
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.company_login_attempts}
                      isInvalid={this.state.validate.company_login_attempts.isInvalid}
                      type="number"
                      name="company_login_attempts"
                      value={this.state.company_login_attempts}
                      placeholder=""
                      onChange={event =>
                        this.handlerChangeInput({ company_login_attempts: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.company_login_attempts.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="branch_login_attempts">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    <strong>Tentativar login Ramais:</strong>
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.branch_login_attempts}
                      isInvalid={this.state.validate.branch_login_attempts.isInvalid}
                      type="number"
                      name="branch_login_attempts"
                      value={this.state.branch_login_attempts}
                      placeholder=""
                      onChange={event =>
                        this.handlerChangeInput({ branch_login_attempts: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.branch_login_attempts.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="initial_companies_available_balance">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    <strong>Crédito inicial empresas:</strong>
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      mask={null}
                      className={this.state.validate.initial_companies_available_balance.isInvalid && "is-invalid"}
                      type="text"
                      name="value"
                      value={integerToBRLCurrency(this.state.initial_companies_available_balance)}
                      placeholder=""
                      onChange={event =>
                        this.handlerChangeInput({
                          initial_companies_available_balance: BRLCurrencyToInt(event.target.value)
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.initial_companies_available_balance.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Atualizar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>PABX</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerStore(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="pabx">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    <strong>Atualizar todos os PABX:</strong>
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', maxWidth: '160px' }}>
                      <ButtonLoading
                        isLoading={this.state.isLoadingUpdatePabx}
                        text="Atualizar"
                        customClass={styles.customBtnMargin}
                      />
                    </div>

                  </Col>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          isActive={this.state.isAlertOn}
          toggleAlert={this.handlerCloseAlert}
          title={this.state.alertMessage.title}
          text={this.state.alertMessage.message}
        />
      </div>
    );
  }
}

export default Config;