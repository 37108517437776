import React, { Component } from "react";

import styles from "./styles.module.css";

import Header from "../../../../../../components/Header";
import Modal from "../../../../../../components/Modal";
import ButtonLoading from "../../../../../../components/Buttons/ButtonLoading";
import Button from '../../../../../../components/Buttons/Button';

import { Form, Col, Row } from "react-bootstrap";

import api from "../../../../../../services/api";
import { API_GUARD } from "../../../../../../services/env";
import { branch } from "../../../../../../services/BranchService";
import MultiSelect from "../../../../../../components/Inputs/Bootstrap/MultiSelect";


export default class EditBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...branch,
      isLoadingForm: true,
      advancedToggled: false,
      cleanMultiSelect: false,
      companies: [],
      company_id: "",
      isLoading: false,
      isAlertOn: false,
      forgotModal: false,
      user_occulted: false,

      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.name = React.createRef();
    this.qualifyFalse = React.createRef();
    this.qualifyTrue = React.createRef();
    this.toggleIsLoadForm = this.toggleIsLoadForm.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.getEditBranch = this.getEditBranch.bind(this);
  }


  toggleCleanMultiSelect() {
    this.setState({ cleanMultiSelect: !this.state.cleanMultiSelect });
  }

  clearForm() {
    this.name.current.focus();
  }

  componentDidMount() {
  
    this.name.current.focus();
    this.getEditBranch();
    this.getUsersCompany();
  }

  async getEditBranch() {
    this.toggleIsLoadForm(true);
    const { params } = this.props.match;
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/company_users/${params.id}/show`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { user } = response.data;
      const { company_id } = user;
      this.setState({ company_id });
      // console.log(response)
      const a =  response.data.user;
      const user_occulte = response.data.user.hidden_user;
      this.setState({ user_occulted: user_occulte });
      await this.setState(a);

    }
    this.toggleIsLoadForm(false);
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn
    });
    const { history } = this.props;
    history.push(`/admin/usuarios`);
  }


  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleIsLoadForm(boolean) {
    this.setState({ isLoadingForm: boolean });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  handlerChangeInputU(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  handleChange(value)
  {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async getUsersCompany() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.get(`/v1/${API_GUARD}/company_users/create`, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { companies } = response.data;
        this.setState({ companies });
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.setState({ isLoading: false });
  }
  async handlerUpdate(event) {
    event.preventDefault();
    const { params } = this.props.match;
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        name,
        email,
        phone,
        secret,
        password_blocked,
        user_occulted,
        company_id,
      } = this.state;
      try {
        const data = {
          "password_blocked":  password_blocked,
          "hidden_user": user_occulted,
          "name": name,
          "email": email,
          "phone": phone,
          "password": secret,
          "company_id" : company_id
        }
        // console.log(data)
        const access_token = await localStorage.getItem("access_token");
        const response = await api.put(
          `/v1/${API_GUARD}/company_users/${params.id}`,data,
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.clearForm();
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const {
      name,
      email,
      phone,
      secret,
      company_id,
      cleanMultiSelect,
      isLoadingForm,
      validate,
    } = this.state;

    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };

    // const alignAccordingLabel = {
    //   xs: { span: 12 },
    //   sm: { span: 2 },
    //   md: { offset: 1, span: 2 },
    //   lg: { span: 2 }
    // };

    // const alignAccordingInput = {
    //   xs: { span: 12 },
    //   sm: { span: 7 },
    //   md: { span: 7 },
    //   lg: { span: 7 }
    // };
    const { companies } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="Usuários" description="Editar Usuário" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Editar Usuário</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerUpdate(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nome:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.name}
                      isInvalid={validate.name.isInvalid}
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Nome"
                      onChange={event =>
                        this.handlerChangeInput({ name: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.name.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    E-mail:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.email.isInvalid}
                      type="email"
                      name="email"
                      value={email}
                      placeholder="E-mail"
                      onChange={event =>
                        this.handlerChangeInput({ email: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.email.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalPhone">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Telefone:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.phone.isInvalid}
                      type="phone"
                      name="Telefone"
                      value={phone}
                      placeholder="phone"
                      onChange={event =>
                        this.handlerChangeInput({ phone: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.phone.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="cities">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Empresa:
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={cleanMultiSelect}
                      isLoading={isLoadingForm}
                      defaultSelected={[{ id: company_id }]}
                      isInvalid={validate.company_id.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="Selecione uma empresa..."
                      options={companies}
                      onChange={event => {
                        event.length > 0
                          ? this.handlerChangeInput({ company_id: event[0].id })
                          : this.handlerChangeInput({ company_id: "" })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.company_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user_occulted">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Usuário oculto:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={this.state.user_occulted}
                        onChange={event => {
                          this.handlerChangeInputU({
                            user_occulted: event.target.checked
                          });
                        }
                        }
                      />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                      {validate.user_occulted.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="secret">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Senha:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={validate.secret.isInvalid}
                      type="password"
                      name="secret"
                      value={secret}
                      placeholder="Senha"
                      onChange={event =>
                        this.handlerChangeInput({
                          secret: event.target.value
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.secret.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="password_blocked">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Senha bloqueada:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={this.state.password_blocked}
                        onChange={event => {
                          this.handlerChangeInput({
                            password_blocked: event.target.checked
                          });
                        }
                        }
                      />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                      {validate.password_blocked.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Salvar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.toggleAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}
