import React, { Component } from 'react';

import styles from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default class ButtonLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      text: "",
      customClass: ""
    }
  }

  componentDidMount() {
    const { isLoading, text, customClass } = this.props;

    this.setState({ isLoading, text, customClass });
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.isLoading !== this.props.isLoading) {
      const { isLoading } = this.props;
      this.setState({ isLoading });
    }
  }

  render() {
    return (
      <button
        className={
          this.state.isLoading
            ? styles.btn + " " + styles.loadingButton + " " + this.state.customClass
            : styles.btn + " " + styles.logginButton + " " + this.state.customClass

        }
        type="submit"
      >
        {this.state.isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) :
          this.state.text
        }

      </button>
    );
  }
}
