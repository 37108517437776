import React, { Component } from 'react';

import styles from "./styles.module.css";

import Header from "../../../../components/Header";
import Modal from "../../../../components/Modal";
import ButtonLoading from "../../../../components/Buttons/ButtonLoading";
import Button from '../../../../components/Buttons/Button';

import { Form, Col, Row } from "react-bootstrap";

import MultiSelectWithAddNew from '../../../../components/Inputs/Bootstrap/MultiSelectWithAddNew';

import api from "../../../../services/api";
import { API_GUARD } from "../../../../services/env";

export default class EditProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingForm: false,
      isLoadingInputs: false,
      isAlertOn: false,
      success_update: false,
      name: '',
      email: '',
      emails: [],
      disabled: '',
      validate: {
        name: {
          message: "",
          isInvalid: false,
        },
        email: {
          message: "",
          isInvalid: false
        },
        disabled: {
          message: "",
          isInvalid: false
        }
      },
      alertMessage: {
        title: "",
        message: "",
      },
    }
    this.name = React.createRef();
    this.disabledTrue = React.createRef();
    this.disabledFalse = React.createRef();
    this.handlerCloseAlert = this.handlerCloseAlert.bind(this);
    this.getEditProvider = this.getEditProvider.bind(this);
  }

  componentDidMount() {
    this.getEditProvider();
    this.name.current.focus();
    this.setDefaultDisabled();
  }

  setDefaultDisabled() {
    if (this.state.disabled) {
      this.disabledTrue.current.checked = "checked";
    } else {
      this.disabledFalse.current.checked = "checked";
    }
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  handlerCloseAlert() {
    this.setState({
      ...this.state,
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    });
    if (this.success_update) {
      this.props.history.push('/admin/fornecedores')
    }
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  async handlerClearForm() {
    await this.setState(prevState => {
      return {
        ...prevState,
        provider: '',
      }
    });
    this.name.current.focus();
    this.setDefaultDisabled();
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerUpdate(event) {
    event.preventDefault();
    const { params } = this.props.match;
    const link = `/v1/${API_GUARD}/provider/${params.id}`;
    if (!this.state.isLoading && !this.state.isLoadingInputs) {
      this.toggleIsLoad(true);
      const {
        name,
        emails,
        disabled
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.put(
          link,
          {
            name,
            emails,
            disabled
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.success_update = true
          this.activeAlert({ title, message });
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  async getEditProvider() {
    this.setState({ isLoadingInputs: true });
    const { params } = this.props.match;
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/provider/${params.id}/edit`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { provider } = response.data;

      this.setState({ ...provider }, () => {
        this.setDefaultDisabled();
      });
    }
    this.setState({ isLoadingInputs: false });
  }

  render() {
    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };

    return (
      <div className={`${styles.container}`}>
        <Header title="Fornecedores" description="Gerenciamento de Fornecedores" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Editar Fornecedor</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerUpdate(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nome:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.name}
                      isInvalid={this.state.validate.name.isInvalid}
                      type="text"
                      name="name"
                      value={this.state.name}
                      placeholder="Nome do fornecedor"
                      onChange={event =>
                        this.handlerChangeInput({ name: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.name.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Emails:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <MultiSelectWithAddNew
                      onChange={(emails) => this.setState({ emails })}
                      placeholder={"Adicionar e-mail"}
                      isInvalid={this.state.validate.email.isInvalid}
                      newSelectionPrefix={"Adicionar novo: "}
                      options={this.state.emails}
                      defaultSelected={this.state.emails}
                      multiple={true}
                      cleanMultiSelect={this.state.cleanMultiSelect}
                      isLoading={this.state.isLoadingForm}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.email.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Habilitado?
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <Form.Check
                      type="radio"
                      label="Sim"
                      isInvalid={this.state.validate.disabled.isInvalid}
                      name="disabled"
                      value={false}
                      onChange={event => this.handlerChangeInput({ disabled: false })}
                      ref={this.disabledFalse}
                    />
                    <Form.Check
                      type="radio"
                      label="Não"
                      isInvalid={this.state.validate.disabled.isInvalid}
                      name="disabled"
                      value={true}
                      ref={this.disabledTrue}
                      onChange={event => this.handlerChangeInput({ disabled: true })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.disabled.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Atualizar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.handlerCloseAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}
