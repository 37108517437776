import React, { Component } from 'react';

import styles from './style.module.css';

export default class Card extends Component {
  render() {
    const { content, title, description } = this.props;
    return (
      <>
        <div className={`${styles.container} ${styles.shadowBox}`}>
          <div className={styles.header}>
            <h2 className={styles.title}>
              {title}
            </h2>
            <small className={styles.discription}>
              {description}
            </small>

          </div>
          <div>
            {content}
          </div>
        </div>
      </>
    );
  }
}
