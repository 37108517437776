export const plan = {
  name: "",
  cadence: "",
  fixed_telephone_tariff: "",
  mobile_telephone_tariff: "",
  international_telephone_tariff: "",
  "0800_telephone_tariff": "",
  "0800_mobile_tariff": "",
  "amount": 0,
  "did": 0,
  "days": 0,
  "trial_days": 0,
  "charges": 0,
  "installments": 0,
  "invoice_reminder": 0,
  "max_branch_number": 0,
  payment_methods: [],
  disabled: true,
  pabx: [],
  validate: {
    pabx: {
      message: "",
      isInvalid: false
    },
    name: {
      message: "",
      isInvalid: false
    },
    cadence: {
      message: "",
      isInvalid: false
    },
    fixed_telephone_tariff: {
      message: "",
      isInvalid: false
    },
    mobile_telephone_tariff: {
      message: "",
      isInvalid: false
    },
    international_telephone_tariff: {
      message: "",
      isInvalid: false
    },
    "0800_telephone_tariff": {
      message: "",
      isInvalid: false
    },
    "0800_mobile_tariff": {
      message: "",
      isInvalid: false
    },
    "amount": {
      message: "",
      isInvalid: false
    },
    "did": {
      message: "",
      isInvalid: false
    },
    "days": {
      message: "",
      isInvalid: false
    },
    "trial_days": {
      message: "",
      isInvalid: false
    },
    "charges": {
      message: "",
      isInvalid: false
    },
    "installments": {
      message: "",
      isInvalid: false
    },
    "invoice_reminder": {
      message: "",
      isInvalid: false
    },
    "max_branch_number": {
      message: "",
      isInvalid: false
    },
    payment_methods: {
      message: "",
      isInvalid: false
    },
    disabled: {
      message: "",
      isInvalid: false
    }
  }
}