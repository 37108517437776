import React, { Component } from 'react';

import styles from './styles.module.css';

import Modal from '../../../../components/Modal';
import Header from '../../../../components/Header';
// import Button from '../../../../components/Buttons/Button';

import api from '../../../../services/api';
import { API_GUARD } from '../../../../services/env';

import ModalConfirm from '../../../../components/ModalConfirm';
import CardAction from '../../../../components/Cards/CardAction';
import { Form } from "react-bootstrap";


export default class PABX extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pabx: [],
      pabx_filter: [],
      isAlertOn: false,
      isExclusionConfirmOn: false,
      block_unblock_option: "",
      block_unblock_id: "",
      view_id: "",
      view:"",
      modalConfirm: {
        open: false,
        title: "",
        text: ""
      },
      alertMessage: {
        title: "",
        message: ""
      }
    }
    this.getPABX = this.getPABX.bind(this);
    this.toggleIsLoad = this.toggleIsLoad.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  componentDidMount() {
    this.getPABX();
  }

  toggleAlert() {
    this.setState({
      isAlertOn: !this.state.isAlertOn,
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  async handlerBlockOrUnblockPabx() {
    try {
      let selected = null;
      const { block_unblock_id, block_unblock_option } = await this.state;
      switch (block_unblock_option) {
        case "block":
          selected = "block"
          break;
        case "unblock":
          selected = "unblock"
          break;
        default:
          selected = null
      }
      if (selected && block_unblock_id) {
        const access_token = await localStorage.getItem("access_token");
        const url = `/v1/${API_GUARD}/pabx/${block_unblock_id}/${selected}`;
        const response = await api.post(url, {}, {
          headers: { Authorization: "Bearer " + access_token }
        });
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
          this.getPABX();
        }
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            const { title, message } = error.response.data;
            this.activeAlert({ title, message });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
      this.toggleIsLoad(false);
    }
  }


  async getPABX() {
    try {
      this.toggleIsLoad(true);
      const access_token = await localStorage.getItem("access_token");
      const url = `/v1/${API_GUARD}/pabx`;
      const response = await api.get(url, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        const { pabx } = response.data;
        console.log(pabx)
        this.setState({ pabx });
        this.setState({pabx_filter:pabx})
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            const { message } = error.response.data;
            this.setState({
              message
            });
            break;
          case 422:
            const { errors } = error.response.data;
            for (let key in errors) {
              this.setState({
                validate: {
                  ...this.state.validate,
                  [key]: {
                    message: errors[key],
                    isInvalid: true
                  }
                }
              });
            }
            break;
          default:
            this.error();
        }
      }
    }
    this.toggleIsLoad(false);
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerChangeInput(value) {
    let data = this.state.pabx_filter
    var filtered_array = data.filter((item)=>{
      let code = item.company.code.toString()
      return item.name.toLowerCase().indexOf(value.search.toLowerCase()) >= 0 || code.indexOf(value.search) >= 0; 
    });
    this.setState({pabx:filtered_array})
  }

  handlerBlockPabx(id) {
    this.setState({
      block_unblock_id: id,
      block_unblock_option: "block"
    });

    const modalConfirm = {
      boolean: true,
      title: "Atenção!",
      message: "Tem certeza de que deseja bloquear esse PABX?"
    };

    this.toggleConfirm(modalConfirm);
  }

  handlerUnblockPabx(id) {
    this.setState({
      block_unblock_id: id,
      block_unblock_option: "unblock"
    });

    const modalConfirm = {
      boolean: true,
      title: "Atenção!",
      message: "Tem certeza de que deseja desbloquear esse PABX?"
    };

    this.toggleConfirm(modalConfirm);
  }

  handlerHeaderListTelephone(id) {
    this.setState({
      view_id: id,
      view: "view"
    });
    const { history } = this.props;
    history.push(`/admin/PABX/numeros`, {params: id});
  }

  handlerHeaderListRamais(id) {
    this.setState({
      view_id: id,
      view: "ramais"
    });
    const { history } = this.props;
    history.push(`/admin/PABX/ramais`, {params: id});
  }

  handlerConfirm() {
    this.toggleConfirm({ boolean: false, title: "", message: "" });
    this.handlerBlockOrUnblockPabx();
  }

  toggleConfirm(data) {
    if (!data) {
      this.setState({
        modalConfirm: {
          open: false,
          title: "",
          message: ""
        }
      });
    } else {
      this.setState({
        modalConfirm: {
          open: data.boolean,
          title: data.title,
          message: data.message
        }
      });
    }
  }

  // handlerHeaderCreatePABX() {
  //   const { history } = this.props;

  //   history.push("/admin/PABX/pabx/adicionar");
  // }

  render() {
    const { pabx } = this.state;
    return (
      <div className={`${styles.container}`}>
        <Header title="PABX" description="Gestão de PABX" />
        <div className={`${styles.body} ${styles.shadowBox}`}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <h2>Listagem de PABX</h2>
            </div>
            <br />
            <div className={styles.boxSearch}>
            <Form.Label >
                Pesquisar:
              </Form.Label>
            
              <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nome ou código PABX"
                  onChange={event =>
                    this.handlerChangeInput({ search: event.target.value })
                  }
                />
              
              {/* <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateTelephone() }}
              /> */}
            </div>
            <div className={styles.itemsHorizontal}>
            {
              pabx.length > 0 ?
              pabx.map((telephone, key) =>
                  (<CardAction
                    key={key}
                    title={telephone.name}
                    description={(telephone.company.name+' ('+telephone.company.code+')')}
                    ddd={telephone.telephone ? telephone.telephone.ddd : ''}
                    blocked={telephone.blocked}
                    qtdRamal={telephone.max_branches}
                    id={key}
                    item={telephone}
                    handlerHeaderListTelephone={(id) => this.handlerHeaderListTelephone(id)}
                    handlerHeaderListRamais={(id) => this.handlerHeaderListRamais(id)}
                    handlerBlockPabx={(id) => this.handlerBlockPabx(id)}
                    handlerUnblockPabx={(id) => this.handlerUnblockPabx(id)}
                    content={
                      <p
                        style={{
                          margin: "auto",
                          color: "#58666C",
                          textAlign: "center",
                          fontSize: "5rem",
                        }}>
                          
                        {telephone.branches_in_use}
                      </p>
                    }
                  />)
                ) : null
            }
          </div>
          </div>
          <Modal
            isActive={this.state.isAlertOn}
            toggleAlert={this.toggleAlert}
            title={this.state.alertMessage.title}
            text={this.state.alertMessage.message}
          />
          <ModalConfirm
            isActive={this.state.modalConfirm.open}
            toggleConfirm={(data) => this.toggleConfirm(data)}
            handlerConfirm={
              (id) => {
                this.handlerConfirm(id);
              }}
            title={this.state.modalConfirm.title}
            text={this.state.modalConfirm.message}
          />
        </div>
      </div>
    );
  }
}
