import React, { Component } from 'react';

import styles from "./styles.module.css";

import { plan } from "../../../../models/Plan";

import Header from "../../../../components/Header";
import Modal from "../../../../components/Modal";
import MultiSelect from "../../../../components/Inputs/Bootstrap/MultiSelect";
import ButtonLoading from "../../../../components/Buttons/ButtonLoading";
import Button from '../../../../components/Buttons/Button';

import { Form, Col, Row } from "react-bootstrap";

import api from "../../../../services/api";
import { API_GUARD } from "../../../../services/env";
import { intToBRLCurrency, BRLCurrencyToInt } from "../../../../services/helpers";

export default class EditPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      isLoading: false,
      isLoadingInputs: false,
      isAlertOn: false,
      ...plan,
      select_payment_method: [],
      selectPABX: [],
      alertMessage: {
        title: "",
        message: "",
      },
    }
    this.name = React.createRef();
    this.disabledTrue = React.createRef();
    this.disabledFalse = React.createRef();
    this.handlerCloseAlert = this.handlerCloseAlert.bind(this);
    this.getEditPlan = this.getEditPlan.bind(this);
  }

  componentDidMount() {
    this.getEditPlan();
    this.name.current.focus();
    this.setDefaultDisabled();
  }

  setDefaultDisabled() {
    if (this.state.disabled) {
      this.disabledTrue.current.checked = "checked";
    } else {
      this.disabledFalse.current.checked = "checked";
    }
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  handlerCloseAlert() {
    if (this.state.success) {
      this.props.history.goBack();
    }
    
    this.setState({
      ...this.state,
      isAlertOn: false,
      alertMessage: {
        title: "",
        message: ""
      }
    });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  async handlerClearForm() {
    await this.setState(prevState => {
      return {
        ...prevState,
        ...plan,
      }
    });
    this.name.current.focus();
    this.setDefaultDisabled();
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerChangeCurrencyInput(value) {
    const key = Object.keys(value)[0];
    const currency = await intToBRLCurrency(parseInt(value[key]));
    this.setState({
      [key]: currency,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  async handlerUpdate(event) {
    event.preventDefault();
    const { params } = this.props.match;
    const link = `/v1/${API_GUARD}/plan/${params.id}`;
    if (!this.state.isLoading && !this.state.isLoadingInputs) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        name,
        cadence,
        fixed_telephone_tariff,
        mobile_telephone_tariff,
        international_telephone_tariff,
        amount,
        did,
        days,
        trial_days,
        payment_methods,
        charges,
        installments,
        invoice_reminder,
        max_branch_number,
        disabled,
        pabx
      } = this.state;
      const zero_tarif = this.state["0800_telephone_tariff"];
      const zero_tarif_mobile = this.state["0800_mobile_tariff"];
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.put(
          link,
          {
            name,
            cadence,
            fixed_telephone_tariff,
            mobile_telephone_tariff,
            international_telephone_tariff,
            amount,
            did,
            days,
            trial_days,
            payment_methods,
            charges,
            installments,
            invoice_reminder,
            max_branch_number,
            "0800_telephone_tariff": zero_tarif,
            "0800_mobile_tariff": zero_tarif_mobile,
            disabled,
            pabx
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.setState({success: true});
          this.activeAlert({ title, message });
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  async getEditPlan() {
    this.setState({ isLoadingInputs: true });
    const { params } = this.props.match;
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/plan/${params.id}/edit`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { plan, selectPABX, select_payment_method } = response.data;
      const filterIdFromPABX = plan.pabx.map(item => { return item.id });
      const filterIdFromPaymentMethod = plan.payment_methods.map(item => { return item.id });
      const selectPABXFormated = selectPABX.map(item => { return { id: item.id, name: `${item.company ? item.company.name : ""}(${item.name})` } });

      this.setState({ ...plan, payment_methods: filterIdFromPaymentMethod, pabx: filterIdFromPABX, selectPABX: selectPABXFormated, select_payment_method }, () => {
        this.setDefaultDisabled();
      });
    }
    this.setState({ isLoadingInputs: false });
  }

  render() {
    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 3 },
      md: { offset: 2, span: 3 },
      lg: { offset: 2, span: 3 }
    };
    const alignInput = {
      xs: { span: 6 },
      sm: { span: 5 },
      md: { span: 5 },
      lg: { span: 5 }
    };

    return (
      <div className={`${styles.container}`}>
        <Header title="Planos" description="Gerenciamento de Planos" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Editar Plano</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerUpdate(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Nome:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      ref={this.name}
                      isInvalid={this.state.validate.name.isInvalid}
                      type="text"
                      name="name"
                      value={this.state.name}
                      placeholder="Nome do plano"
                      onChange={event =>
                        this.handlerChangeInput({ name: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.name.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalCadence">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Cadência:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate.cadence.isInvalid}
                      type="text"
                      name="cadence"
                      value={this.state.cadence}
                      placeholder="Cadência do plano"
                      onChange={event =>
                        this.handlerChangeInput({ cadence: event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.cadence.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalFixedTelephoneTariff">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Tarifa fixo:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate.fixed_telephone_tariff.isInvalid}
                      type="text"
                      name="fixed_telephone_tariff"
                      value={intToBRLCurrency(this.state.fixed_telephone_tariff)}
                      placeholder="Tarifa ligações fixo"
                      onChange={event =>
                        this.handlerChangeInput({ fixed_telephone_tariff: BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.fixed_telephone_tariff.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalMobileTelephoneTariff">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Tarifa móvel:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate.mobile_telephone_tariff.isInvalid}
                      type="text"
                      name="mobile_telephone_tariff"
                      value={intToBRLCurrency(this.state.mobile_telephone_tariff)}
                      placeholder="Tarifa ligações móveis"
                      onChange={event =>
                        this.handlerChangeInput({ mobile_telephone_tariff: BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.mobile_telephone_tariff.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalInternationalTelephoneTariff">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Tarifa internacional:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate.international_telephone_tariff.isInvalid}
                      type="text"
                      name="international_telephone_tariff"
                      value={intToBRLCurrency(this.state.international_telephone_tariff)}
                      placeholder="Tarifa ligações interncionais"
                      onChange={event =>
                        this.handlerChangeInput({ international_telephone_tariff: BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.international_telephone_tariff.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontal0800TelephoneTariff">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                  Tarifa móvel 0800:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["0800_mobile_tariff"].isInvalid}
                      type="text"
                      name="0800_mobile_tariff"
                      value={intToBRLCurrency(this.state["0800_mobile_tariff"])}
                      placeholder="Tarifa móvel 0800"
                      onChange={event =>
                        this.handlerChangeInput({ "0800_mobile_tariff": BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["0800_mobile_tariff"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontal0800TelephoneTariff">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Tarifa fixo 0800:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["0800_telephone_tariff"].isInvalid}
                      type="text"
                      name="0800_telephone_tariff"
                      value={intToBRLCurrency(this.state["0800_telephone_tariff"])}
                      placeholder="Tarifa fixo 0800"
                      onChange={event =>
                        this.handlerChangeInput({ "0800_telephone_tariff": BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["0800_telephone_tariff"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="amount">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Preço do Ramal:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["amount"].isInvalid}
                      type="tel"
                      name="amount"
                      value={intToBRLCurrency(this.state["amount"])}
                      placeholder="Preço do Ramal"
                      onChange={event =>
                        this.handlerChangeInput({ "amount": BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["amount"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}  className="align-items-center" controlId="did">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Valor do DID:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["did"].isInvalid}
                      type="tel"
                      name="did"
                      value={intToBRLCurrency(this.state["did"])}
                      placeholder="Valor do DID"
                      onChange={event =>
                        this.handlerChangeInput({ "did": BRLCurrencyToInt(event.target.value) })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["did"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="days">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Intervalo de Cobranças:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["days"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="days"
                      value={this.state["days"]}
                      placeholder="Quantidades de dias entre cobranças"
                      onChange={event =>
                        this.handlerChangeInput({ "days": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["days"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="trial_days">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Período de Teste:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["trial_days"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="trial_days"
                      value={this.state["trial_days"]}
                      placeholder="Quantidade de dias para teste (trial)."
                      onChange={event =>
                        this.handlerChangeInput({ "trial_days": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["trial_days"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="charges">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Quantidade de Cobranças:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["charges"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="charges"
                      value={this.state["charges"]}
                      placeholder="Quantidade de Cobranças."
                      onChange={event =>
                        this.handlerChangeInput({ "charges": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["charges"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="installments">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Quantidade de Parcelas:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["installments"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="installments"
                      value={this.state["installments"]}
                      placeholder="Quantidade de Parcelas."
                      onChange={event =>
                        this.handlerChangeInput({ "installments": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["installments"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoice_reminder">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Lembrete de Pagamento:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["invoice_reminder"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="invoice_reminder"
                      value={this.state["invoice_reminder"]}
                      placeholder="Quantidade de dias para lembrar pagamento do boleto."
                      onChange={event =>
                        this.handlerChangeInput({ "invoice_reminder": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["invoice_reminder"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="max_branch_number">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Quantidade de Ramais:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      isInvalid={this.state.validate["max_branch_number"].isInvalid}
                      type="number"
                      min="0"
                      step="1"
                      name="max_branch_number"
                      value={this.state["max_branch_number"]}
                      placeholder="Quantidade de máxima de ramais."
                      onChange={event =>
                        this.handlerChangeInput({ "max_branch_number": event.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate["max_branch_number"].message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="payment_methods">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Métodos de pagamento:
                  </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={false}
                      isLoading={this.state.isLoadingInputs}
                      defaultSelected={this.state.payment_methods.map(item => { return { id: item } })}
                      isInvalid={this.state.validate.payment_methods.isInvalid}
                      multiple={true}
                      label="name"
                      placeholder="Selecione os métodos de pagamento que deseja vincular ao plano..."
                      options={this.state.select_payment_method}
                      selected={this.state.payment_methods}
                      onChange={event => {
                        console.log(event.map((item) => { return item.id }));
                        event.length > 0
                          ? this.handlerChangeInput({
                            payment_methods: event.map((item) => { return item.id })
                          })
                          : this.handlerChangeInput({ payment_methods: [] })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.payment_methods.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="pabx">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    PABX:
                  </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={false}
                      isLoading={this.state.isLoadingInputs}
                      defaultSelected={this.state.pabx.map(item => { return { id: item } })}
                      isInvalid={this.state.validate.pabx.isInvalid}
                      multiple={true}
                      label="name"
                      placeholder="Selecione PABX que deseja vincular ao plano..."
                      options={this.state.selectPABX}
                      selected={this.state.pabx}
                      onChange={event =>
                        event.length > 0
                          ? this.handlerChangeInput({
                            pabx: event.map((item) => { return item.id })
                          })
                          : this.handlerChangeInput({ pabx: [] })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.pabx.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Habilitado?
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <Form.Check
                      type="radio"
                      label="Sim"
                      isInvalid={this.state.validate.disabled.isInvalid}
                      name="disabled"
                      value={false}
                      onChange={event => this.handlerChangeInput({ disabled: false })}
                      ref={this.disabledFalse}
                    />
                    <Form.Check
                      type="radio"
                      label="Não"
                      isInvalid={this.state.validate.disabled.isInvalid}
                      name="disabled"
                      value={false}
                      ref={this.disabledTrue}
                      onChange={event => this.handlerChangeInput({ disabled: true })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.validate.disabled.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Atualizar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={this.state.isAlertOn}
              toggleAlert={this.handlerCloseAlert}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div>
    );
  }
}
