import React, { Component } from 'react';

import styles from './style.module.css';

import ButtonReverse from '../Buttons/ButtonReverse';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      title: "",
      text: "",
      content: null
    };
    this.toggleAlert = this.props.toggleAlert.bind(this);
  }

  componentDidMount() {
    const { isActive, title, text, content } = this.props;
    this.setState({ isActive, title, text, content });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      const { isActive, title, text, content } = this.props;
      this.setState({ isActive, title, text, content });
    }
  }

  render() {
    const { isActive, title, text, content } = this.state;
    return (
      <>
        {
          isActive ?
            (
              <div className={styles.modal}>
                <div className={styles.modalContainer}>
                  <div className={styles.modalHeader}>
                    <h3>{title}</h3>
                  </div>
                  <div className={styles.modalBody}>
                    {
                      text ?
                        <p>{text}</p>
                        : content
                    }
                  </div>
                  <div className={styles.modalFooter}>
                    <ButtonReverse
                      onClick={() => { this.toggleAlert(false) }}
                      text="Fechar"
                    />
                  </div>
                </div>
              </div>
            )
            : null
        }
      </>
    );
  }
}
