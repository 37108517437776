import React, { Component } from 'react';

import styles from './styles.module.css';

import api from '../../../services/api';
import { API_GUARD } from '../../../services/env';

import Card from '../../../components/Card';
import Header from '../../../components/Header';
import Modal from '../../../components/Modal';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOn: false,
      statistics: [],
      top_clients:[]
    };
    this.getStatistics = this.getStatistics.bind(this);
    this.getDashboardPerSecond = this.getDashboardPerSecond.bind(this);
  }

  componentDidMount() {
    this.getStatistics();
    this.getDashboardPerSecond();
  }


componentWillUnmount() {
  clearInterval(this.dashboardInterval);
}

getDashboardPerSecond() {
  this.dashboardInterval = setInterval(() => this.getStatistics(), 5000);
}

  toggleAlert() {
    this.setState({ isAlertOn: !this.state.isAlertOn });
  }k

  async getStatistics() {
    try {
      const access_token = await localStorage.getItem("access_token");

      const response = await api.get(`/v1/${API_GUARD}/dashboard`, {
        headers: { Authorization: "Bearer " + access_token }
      });

      if (response.status && response.status === 200) {
        const { statistics } = response.data;
        const { top_clients } = response.data
        this.setState({ statistics });
        this.setState({top_clients})
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          default:
            console.warn("Ops... algo de errado aconteceu!");
        }
      }
    }
  }
 

  render() {
    const { statistics, top_clients } = this.state;
    return (
      <>
        <div className={styles.container}>
          <Header title="Dashboard" description="Bem-vindo!" />
          <div className={styles.itemsHorizontal}>
            {
              statistics.length > 0 ?
                statistics.map((item, key) =>
                  (<Card
                    key={key}
                    title={item.title}
                    description={item.description}
                    content={
                      <p
                        style={{
                          margin: "auto",
                          color: "#58666C",
                          textAlign: "center",
                          marginTop: "2rem",
                          fontSize: "5rem",
                        }}>
                        {item.count}
                      </p>
                    }
                  />)
                ) : null
            }
          </div>
          <div className={styles.titleTop}>
            <h1>Top Clientes</h1>
          </div>
          <div className={styles.itemsHorizontal}>
            {
              top_clients.length > 0 ?
                top_clients.map((item, key) =>
                  (<Card
                    key={key}
                                        
                    content={
                      <div className={styles.topContainer}>
                        <h2>{item.company_name}</h2>
                        <h3>Minutos utilizados</h3>
                        <p className={styles.total}>
                          {item.duration} min
                        </p>
                        <h3>Valor Total</h3>
                        <p className={styles.total}>
                          {item.total}
                        </p>
                        
                      </div>
                    }
                  />)
                ) : null
            }
          </div>
          
          <hr/>

          <Modal
            isActive={this.state.isAlertOn}
            title={"Nova Mensagem!"}
            content={(
              <p>
                A fatura referente ao mês de dezembro ainda
                está em aberta, favor efetuar o pagamento e
                      enviar comprovante no e-mail:<br /><br />
                <a href="mailto:suporte@wfgsoluctions.com.br" className="link">
                  suporte@wfgsoluctions.com.br
                      </a>.
                    </p>
            )} toggleAlert={() => this.toggleAlert()} />
        </div>
      </>
    );
  }
}