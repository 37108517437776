import React, { Component } from "react";

import styles from "./styles.module.css";

import Header from "../../../../../../components/Header";
import Modal from "../../../../../../components/Modal";
import ButtonLoading from "../../../../../../components/Buttons/ButtonLoading";
import Button from "../../../../../../components/Buttons/Button";
import { Form, Col, Row } from "react-bootstrap";

import api from "../../../../../../services/api";
import { API_GUARD } from "../../../../../../services/env";
import MultiSelect from "../../../../../../components/Inputs/Bootstrap/MultiSelect";

export default class CreateTelephone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      telephones: [],
      validate: {
        city_id: {
          message: "",
          isInvalid: false
        },
        tel_id: {
          message: "",
          isInvalid: false
        }
      },

      isLoadingForm: true,
      advancedToggled: false,

      isLoading: false,
      isAlertOn: false,
      forgotModal: false,

      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.getCreateTelephone = this.getCreateTelephone.bind(this);
  }

  clearForm() {
    this.setState({
      city_id : "",
      tel_id: "",
      validate: {
        city_id: {
          message: "",
          isInvalid: false
        },
        tel_id: {
          message: "",
          isInvalid: false
        }
      },

    });
  }

  componentDidMount() {
    this.getCreateTelephone();
  }

  async getCreateTelephone() {
    this.toggleIsLoadForm(true);
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/telephones/create`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { cities } = response.data;
      this.setState({ cities });
    }
    this.toggleIsLoadForm(false);
  }

  toggleAlert(boolean) {
    this.setState(prevState => {
      return {
        isAlertOn: boolean
      }
    });
    const { history } = this.props;
    const id = this.props.location.state.params;
    history.push(`/admin/PABX/numeros`, {params: id});
  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleIsLoadForm(boolean) {
    this.setState({ isLoadingForm: boolean });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }
  async handlerChangeTelephone(value) {
    const city_id = value.city_id;
    this.toggleIsLoadForm(true);
    const access_token = await localStorage.getItem("access_token");
    const response = await api.get(`/v1/${API_GUARD}/pabx/telephone/numbers/${city_id}`, {
      headers: { Authorization: "Bearer " + access_token }
    });

    if (response.status === 200) {
      const { telephones } = response.data;
      this.setState({ telephones });
    }
    this.toggleIsLoadForm(false);
  }


  async handlerStore(event) {
    event.preventDefault();
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        tel_id
      } = this.state;

      const id = this.props.location.state.params;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.post(
          `/v1/${API_GUARD}/pabx/${id}/telephone`,
          {
            "telephone_id": tel_id
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message } = response.data;
          this.activeAlert({ title, message });
        }
        this.clearForm();
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
            // 
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const {
      cleanMultiSelect,
      isLoadingForm,
      city_id,
      tel_id,
      telephones,
      cities,
      validate,
      isAlertOn
    } = this.state;

    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 2, span: 1 },
      md: { offset: 2, span: 1 },
      lg: { offset: 2, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 }
    };

    return (
      <div className={`${styles.container}`}>
        <Header title="Telefones" description="Telefones PABX" />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Vincular novo telefone</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerStore(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="cities">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Cidade:
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={cleanMultiSelect}
                      isLoading={isLoadingForm}
                      defaultSelected={[{ id: city_id }]}
                      isInvalid={validate.city_id.isInvalid}
                      multiple={false}
                      label="name"
                      placeholder="Selecione uma cidade..."
                      options={cities}
                      onChange={event => {
                        event.length > 0
                        ? this.handlerChangeTelephone({ city_id: event[0].id })
                        : this.handlerChangeTelephone({ city_id: "" })
                        event.length > 0
                          ? this.handlerChangeInput({ city_id: event[0].id })
                          : this.handlerChangeInput({ city_id: "" })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.city_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="telephones">
                  <Form.Label
                    column
                    xs={alignLabel.xs} md={alignLabel.md}
                  >
                    Telefone:
                            </Form.Label>
                  <Col
                    xs={alignInput.xs} md={alignInput.md}
                  >
                    <MultiSelect
                      cleanMultiSelect={cleanMultiSelect}
                      isLoading={isLoadingForm}
                      defaultSelected={[{ id: tel_id }]}
                      isInvalid={validate.tel_id.isInvalid}
                      multiple={false}
                      label="number"
                      placeholder="Selecione um telefone..."
                      options={telephones}
                      onChange={event => {
                        event.length > 0
                          ? this.handlerChangeInput({ tel_id: event[0].id })
                          : this.handlerChangeInput({ tel_id: "" })
                      }
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.tel_id.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Cadastrar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={isAlertOn}
              toggleAlert={(boolean) => this.toggleAlert(boolean)}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div >
    );
  }
}
