import React, { Component } from "react";

import styles from "./styles.module.css";

import Header from "../../../../components/Header";
import Modal from "../../../../components/Modal";
import ButtonLoading from "../../../../components/Buttons/ButtonLoading";
import Button from "../../../../components/Buttons/Button"

import { Form, Col, Row } from "react-bootstrap";

import { integerToBRLCurrency, BRLCurrencyToInt } from '../../../../services/helpers';

import api from "../../../../services/api";
import { API_GUARD } from "../../../../services/env";

export default class Recharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: "",
      value: "",
      company: {},

      validate: {
        company_id: {
          message: "",
          isInvalid: false
        },
        value: {
          message: "",
          isInvalid: false
        }
      },

      isLoadingForm: true,
      advancedToggled: false,

      isLoading: false,
      isAlertOn: false,
      forgotModal: false,

      alertMessage: {
        title: "",
        message: ""
      }
    };
    this.loadCompany = this.loadCompany.bind(this);
    this.getIdByParams = this.getIdByParams.bind(this);
    this.setup = this.setup.bind(this);
  }

  clearForm() {
    this.setState({
      value: "",
      validate: {
        company_id: {
          message: "",
          isInvalid: false
        },
        value: {
          message: "",
          isInvalid: false
        }
      },

    });
  }

  toggleAlert(boolean) {
    this.setState(prevState => {
      return {
        isAlertOn: boolean
      }
    });

  }

  toggleIsLoad(boolean) {
    this.setState({ isLoading: boolean });
  }

  toggleIsLoadForm(boolean) {
    this.setState({ isLoadingForm: boolean });
  }

  activeAlert(alertMessage) {
    const { title, message } = alertMessage;
    this.setState({
      ...this.state,
      isAlertOn: true,
      alertMessage: {
        title,
        message
      }
    });
  }

  error() {
    const alert = {
      title: "Ops... Algo deu errado.",
      message: "Não foi possível completar sua requisição."
    }
    this.activeAlert(alert);
  }

  handlerChangeInput(value) {
    const key = Object.keys(value)[0];
    this.setState({
      ...value,
      validate: {
        ...this.state.validate,
        [key]: {
          message: "",
          isInvalid: false
        }
      }
    });
  }

  componentDidMount() {
    this.setup();
  }

  async setup() {
    this.getIdByParams()
      .then(() => {
        this.loadCompany()
      });
  }

  async getIdByParams() {
    const { params } = this.props.match;
    this.setState({ company_id: params.id })
  }

  async loadCompany() {
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      const {
        company_id
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.get(
          `/v1/${API_GUARD}/company/${company_id}`,
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { company } = response.data;
          this.setState({ company });
        }
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              this.error();
              break;
            default:
              this.error();
            // 
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  async handlerStore(event) {
    event.preventDefault();
    if (!this.state.isLoading) {
      this.toggleIsLoad(true);
      // const { history } = this.props;
      const {
        company_id,
        value
      } = this.state;
      try {
        const access_token = await localStorage.getItem("access_token");
        const response = await api.post(
          `/v1/${API_GUARD}/recharge`,
          {
            company_id,
            value
          },
          {
            headers: { Authorization: "Bearer " + access_token }
          }
        );
        if (response.status && response.status === 200) {
          const { title, message, available_balance } = response.data;
          this.activeAlert({ title, message });
          this.setState(prevState => {
            return {
              ...prevState,
              company: {
                ...prevState.company,
                available_balance
              }
            }
          })
        }
        this.clearForm();
        this.toggleIsLoad(false);
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              const { title, message } = error.response.data;
              this.activeAlert({ title, message });
              break;
            case 422:
              const { errors } = error.response.data;
              for (let key in errors) {
                this.setState({
                  validate: {
                    ...this.state.validate,
                    [key]: {
                      message: errors[key],
                      isInvalid: true
                    }
                  }
                });
              }
              break;
            default:
              this.error();
            // 
          }
        }
        this.toggleIsLoad(false);
      }
    }
  }

  render() {
    const {
      value,
      validate,
      isAlertOn
    } = this.state;

    const alignLabel = {
      xs: { span: 3 },
      sm: { offset: 3, span: 1 },
      md: { offset: 3, span: 1 },
      lg: { offset: 3, span: 1 }
    };
    const alignInput = {
      xs: { span: 8 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 }
    };

    return (
      <div className={`${styles.container}`}>
        <Header title="Recarga" description="Recarregar créditos da empresa." />
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h2 className={styles.cardTitle}>Empresa: {this.state.isLoading ? "Carregando informações..." : this.state.company?.name}</h2>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.formContainer}>
              <Form
                onSubmit={event => this.handlerStore(event)}
                className={styles.form}
                ref="form"
              >
                <Form.Group as={Row} controlId="formHorizontalDDD">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Saldo atual:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      readOnly
                      mask={null}
                      type="text"
                      value={
                        this.state.company ? this.state.company.available_balance ? integerToBRLCurrency(this.state.company?.available_balance) : "R$ 0,0000" : "R$ 0,0000"

                      }
                      placeholder="Saldo Atual"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalValue">
                  <Form.Label column xs={alignLabel.xs} md={alignLabel.md}>
                    Valor:
                  </Form.Label>
                  <Col xs={alignInput.xs} md={alignInput.md}>
                    <Form.Control
                      mask={null}
                      className={validate.value.isInvalid && "is-invalid"}
                      type="text"
                      name="value"
                      value={integerToBRLCurrency(value)}
                      placeholder="Valor da recarga"
                      onChange={event =>
                        this.handlerChangeInput({
                          value: BRLCurrencyToInt(event.target.value)
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {validate.value.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                    <Button text='Voltar' arrowLeft={true} btnClass="btn-back-radius"  onClick={this.props.history.goBack}/>
                    <ButtonLoading
                      isLoading={this.state.isLoading}
                      text="Cadastrar"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <Modal
              isActive={isAlertOn}
              toggleAlert={(boolean) => this.toggleAlert(boolean)}
              title={this.state.alertMessage.title}
              text={this.state.alertMessage.message}
            />
          </div>
        </div>
      </div >
    );
  }
}
